import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import account from './account'
import loc from './loc'
import geo from './geo'
import price from './price'
import order from './order'
import transaction from './transaction'
import mq from './mq'
import machine from './machine'
import timer from './timer'
import session from './session'
import limits from './limits'
import levelUpProgress from './level-up-progress'
import levelUpIframe from './level-up-iframe'
import survey from './survey'
import identification from './identification'
import faast from './faast'
import sell from './sell'
import currency from './currency'
import promo from './promo'
import asset from './asset'
import refund from './refund'
import provider from './provider'

export default combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  account,
  loc,
  geo,
  price,
  order,
  transaction,
  mq,
  machine,
  timer,
  session,
  limits,
  levelUpProgress,
  levelUpIframe,
  survey,
  identification,
  faast,
  sell,
  currency,
  promo,
  asset,
  refund,
  provider
})
