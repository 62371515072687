import BigNumber from 'bignumber.js'
import queryString from 'querystring'
import i18n from 'i18n'
import config from '../config'

export const copyToClipboard = (value) => {
  const textArea = document.createElement('textarea')
  textArea.style.position = 'fixed'
  textArea.style.top = 0
  textArea.style.left = 0
  textArea.style.width = '2em'
  textArea.style.height = '2em'
  textArea.style.padding = 0
  textArea.style.border = 'none'
  textArea.style.outline = 'none'
  textArea.style.boxShadow = 'none'
  textArea.style.background = 'transparent'
  textArea.value = value
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)
}

export const calcMax = (sellLimit = 0, valueList = [], countList = []) => {
  const machineMax = valueList.reduce((acc, val, i) => {
    return acc + val * countList[i]
  }, 0)
  return machineMax > sellLimit ? sellLimit : machineMax
}

export const maxMinAvailable = (amount, limit, valueOfBills, numOfBills) => {
  // large denominations (i.e. VND) cause the calcBills function to slow to a crawl
  // this will decrease the size of the denominations so the function can run quicker:
  const minValue = Math.min(...valueOfBills)
  let x = 0
  while (minValue >= Math.pow(10, x)) x++
  const powerTen = Math.pow(10, x - 1)
  if (powerTen > 1) {
    const maxMin = maxMinAvailable(amount.div(powerTen), limit.div(powerTen), valueOfBills.map(a => a / powerTen), numOfBills)
    return { max: maxMin.max * powerTen, min: maxMin.min * powerTen }
  }

  amount = amount.integerValue(BigNumber.ROUND_CEIL)
  const overLimit = amount.isGreaterThan(limit)
  let maxFiat = overLimit ? limit.integerValue().toNumber() : amount.integerValue().toNumber()
  let foundAvailable = false
  let availableAmounts
  const numLimit = limit.toNumber()
  while (foundAvailable === false) {
    availableAmounts = calcBills(maxFiat, valueOfBills, numOfBills)
    if (maxFiat > 0 && availableAmounts[0] > numLimit) {
      maxFiat -= 1
    } else {
      foundAvailable = true
    }
  }
  return { max: availableAmounts[0], min: availableAmounts[1] }
}

export const calcBills = (amount, valueOfBills, numOfBills) => {
  var numLevels = valueOfBills.length
  var maxSum = 0
  var max = 0
  var min = 0
  var billList = []
  var realAmount = amount
  var i, j
  if (numLevels <= 0) {
    return [0, 0]
  }
  amount = amount + valueOfBills[numLevels - 1]
  for (i = 0; i < numLevels; ++i) {
    for (j = 0; j < numOfBills[i]; j++) {
      billList.push(valueOfBills[i])
      maxSum = maxSum + valueOfBills[i]
    }
  }

  if (maxSum <= 0) {
    return [0, 0]
  }
  if (realAmount > maxSum) {
    realAmount = maxSum
  }

  var f = []
  for (i = 0; i < billList.length; i++) {
    var arr = []
    for (var x = 0; x < amount + 1; x++) arr.push(0)
    f.push(arr)
  }

  for (j = 0; j < billList.length; j++) {
    for (i = 1; i < amount + 1; i++) {
      if (j > 0) {
        f[j][i] = f[j - 1][i]
      }
      if (i - billList[j] >= 0) {
        var z = j - 1
        if (z < 0) z = billList.length - 1
        if (f[z][i - billList[j]] + billList[j] <= i && f[j][i] < f[z][i - billList[j]] + billList[j]) {
          f[j][i] = f[z][i - billList[j]] + billList[j]
        }
      }
    }
  }

  var result = []
  var w = amount
  var wasAdded
  var wt

  for (j = billList.length - 1; j > 0; j--) {
    wasAdded = f[j][w] !== f[j - 1][w]

    if (wasAdded) {
      wt = billList[j]
      result.push(billList[j])
      if (j - 1 === 0) {
        result.push(billList[j - 1])
      }
      w -= wt
    }
  }

  if (f[billList.length - 1][realAmount] === realAmount) {
    max = realAmount
    min = realAmount
    return [max, min]
  }

  for (i = realAmount + 1; i < amount; i++) {
    if (f[billList.length - 1][i] !== f[billList.length - 1][realAmount]) {
      max = f[billList.length - 1][i]
      break
    }
  }
  min = f[billList.length - 1][realAmount]
  return [max, min]
}

export const sessionLevel = (session) => {
  switch (session) {
    /* case config.sessionStatus.UNAUTHENTICATED: */
    /* return 1 */
    /* case config.sessionStatus.AUTHENTICATED: */
    case config.sessionStatus.UNINITIATED:
      return 1
    case config.sessionStatus.INITIATED_ORDER:
    case config.sessionStatus.REFUND_SUBMITTED:
      return 2
    case config.sessionStatus.AWAITING_PAYMENT:
      return 3
    case config.sessionStatus.COINS_SENT:
    case config.sessionStatus.STATED_SENT:
    case config.sessionStatus.WALLET_SENT:
    case config.sessionStatus.TIMED_OUT:
      return 4
    default:
      return 0
  }
}

export const capitalize = (value) => {
  if (typeof value !== 'string') return value
  return value[0].toUpperCase() + value.substr(1)
}

export const orderStatus = (state, statusFlags = {}, txnStatus) => {
  let status
  if (statusFlags.isCompleted) {
    status = 'complete'
  } else if (statusFlags.isFailed) {
    status = 'exception'
  } else if (state === 'refunded') {
    status = 'refunded'
  } else if (statusFlags.isFinal) {
    status = 'cancelled'
  } else if (statusFlags.isProcessing || ['public key received', 'cash received', 'pending coins send'].includes(txnStatus)) {
    status = 'in_progress'
  } else if (txnStatus === 'dispense error') {
    status = 'exception'
  } else {
    status = 'other'
  }
  return status
}

export const parseQueryString = (query) => {
  if (typeof query !== 'string') return {}
  if (query.charAt(0) === '?') query = query.slice(1)
  return queryString.parse(query)
}

export const extractTxnHash = (order) => {
  let txnHash
  if ((order.kind === 'btmbuy' || order.kind === 'coinrefund') &&
    order.state === 'completed' && order.withdrawal_details && order.withdrawal_details.tx_hash) {
    txnHash = order.withdrawal_details.tx_hash
  }
  if (order.kind === 'btmsell' && (order.state === 'completed' || order.state === 'refunded') && order.deposit_details && order.deposit_details.sweep && order.deposit_details.sweep.tx_hash) {
    txnHash = order.deposit_details.sweep.tx_hash
  }
  return txnHash
}

export const isSafariIos = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  return iOS && webkit && !ua.match(/CriOS/i)
}

export const extractCountryGmaps = (gmapComp) => {
  let country = ''
  if (Array.isArray(gmapComp.address_components)) {
    const addr = gmapComp.address_components.find(addComp => {
      return Array.isArray(addComp.types) && addComp.types.includes('country')
    })
    if (addr) country = addr.short_name
  }
  return country
}

export const findCsVal = (key, machine, org) => {
  let val
  const setVal = (data) => {
    val = data[`cs_${key}`]
  }
  if (machine[`cs_${key}`]) {
    setVal(machine)
  } else if (org[`cs_${key}`]) {
    setVal(org)
  }
  return val
}

export const sortLocations = (locationList = [], level = 0) => {
  const path = '/locations'
  const locations = { ' ': [] }
  locationList.forEach(l => {
    if (l.country) {
      if (!locations[l.country]) locations[l.country] = { ' ': [] }
      if (l.state) {
        if (!locations[l.country][l.state]) locations[l.country][l.state] = { ' ': [] }
        if (l.city) {
          if (!locations[l.country][l.state][l.city]) locations[l.country][l.state][l.city] = []
          locations[l.country][l.state][l.city].push(l)
        } else {
          locations[l.country][l.state][' '].push(l)
        }
      } else {
        locations[l.country][' '].push(l)
      }
    } else {
      locations[' '].push(l)
    }
  })
  let sorted = Object.keys(locations).sort().map(country => {
    const states = Object.keys(locations[country]).sort().map(state => {
      const cities = Object.keys(locations[country][state]).sort().map(city => {
        return {
          name: city,
          link: `${path}/${country}/${state}/${city}`,
          values: locations[country][state][city],
          parents: [state, country]
        }
      }).filter(a => a.values.length)
      return {
        name: state,
        link: `${path}/${country}/${state}`,
        values: cities,
        parents: [country]
      }
    }).filter(a => a.values.length)
    return {
      name: country,
      link: `${path}/${country}`,
      values: states,
      parents: []
    }
  }).filter(a => a.values.length)

  for (let i = 0; i < level; i++) {
    sorted = sorted.reduce((a, b) => {
      return a.concat(b.values)
    }, [])
  }

  return sorted
}

export const extractAssetList = (currencyList, supportedCryptocurrencies) => {
  const unsortedAssetsList = currencyList.filter(c => supportedCryptocurrencies.includes(c.symbol))
  const assetList = supportedCryptocurrencies.map(symbol => {
    const asset = unsortedAssetsList.find(c => c.symbol === symbol)
    if (asset) return asset
    else return false
  })
  return assetList
}

export const randomizeArray = (original) => {
  const list = [].concat(original)
  const randList = []
  for (let i = 0; i < original.length; i++) {
    const randIx = Math.floor(Math.random() * list.length)
    randList.push(list.splice(randIx, 1)[0])
  }
  return randList
}

export const closestNumber = (list, goal) => {
  return list.reduce((prev, curr) => {
    return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
  })
}

export const randomizeFaastCurrencies = (currencies = [], limit) => {
  const coins = currencies
    .reduce((acc, c) => {
      if (!c.disabled) {
        if (config.popularCoins.includes(c.symbol)) {
          acc.popular.push(c)
        } else {
          acc.other.push(c)
        }
      }
      return acc
    }, { popular: [], other: [] })
  const popular = randomizeArray(coins.popular)
  const other = randomizeArray(coins.other)
  const list = []
  for (let i = 0; i < (limit || currencies.length); i++) {
    const pullFrom = popular.length ? popular : other
    list.push(pullFrom.shift())
  }
  return list
}

export const parseLevels = (levels) => {
  const valueList = []
  const countList = []
  if (Array.isArray(levels)) {
    levels.forEach((noteData) => {
      valueList.push(Number(noteData.denomination))
      const count = Number(noteData.count)
      // staging billacceptor lists a bizarelly high amount of notes in recycler
      // count is limited here
      countList.push(count > 200 ? 200 : count)
    })
  }
  return { valueList, countList }
}

export const makeCancelable = (promise) => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject(new Error('promise cancelled')) : resolve(val),
      error => hasCanceled_ ? reject(new Error('promise cancelled')) : reject(error)
    )
  })

  return {
    promise: wrappedPromise,
    cancel () {
      hasCanceled_ = true
    }
  }
}

export const getLanguage = (rootOnly) => {
  const language = i18n.language || 'en'
  const languages = i18n.languages || []
  let supported = [language].concat(languages).find(l => !!config.languages[l] && (!rootOnly || !l.includes('-')))
  if (!supported) supported = 'en'
  return supported
}
