import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState, {
  status: config.sessionStatus.UNINITIATED,
  termsRequired: false,
  termsAccepted: false,
  flow: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_SESSION:
      return initialState
    case types.SET_SESSION_STATUS:
      return Object.assign({}, state, { status: action.payload })
    case types.SET_TERMS_REQUIRED:
      return Object.assign({}, state, { termsRequired: action.payload })
    case types.SET_TERMS_ACCEPTED:
      return Object.assign({}, state, { termsAccepted: action.payload })
    case types.SET_FLOW:
      return Object.assign({}, state, { flow: action.payload })
    default:
      return state
  }
}
