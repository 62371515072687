import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_TX:
    case types.RESET_SESSION:
    case types.SET_MACHINE:
      return initialState
    case types.SET_TX:
      return Object.assign({}, state, action.payload)
    case types.SET_REF_TX:
      return Object.assign({}, state, { ref_txn_populated: action.payload })
    default:
      return state
  }
}
