import * as types from '../actions/actionTypes'

const initialState = {
  submitted: false,
  passport: {},
  face: {},
  cardFront: {},
  cardBack: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_IDENTIFICATION:
      return initialState
    case types.ADD_IDENTIFICATION: {
      const newId = Object.assign({}, state[action.payload.category], { file: action.payload.id })
      return Object.assign({}, state, {
        [action.payload.category]: newId
      })
    }
    case types.REMOVE_IDENTIFICATION: {
      const newId = Object.assign({}, state[action.payload.category], { file: undefined })
      return Object.assign({}, state, {
        [action.payload.category]: newId
      })
    }
    case types.ADD_ID_URL: {
      const newId = Object.assign({}, state[action.payload.category], { url: action.payload.url })
      return Object.assign({}, state, {
        [action.payload.category]: newId
      })
    }
    case types.UPLOAD_ID_ERROR: {
      const newId = Object.assign({}, state[action.payload.category], { isFetching: false, success: false })
      return Object.assign({}, state, {
        [action.payload.category]: newId
      })
    }
    case types.UPLOAD_ID_SUCCESS: {
      const newId = Object.assign({}, state[action.payload.category], { isFetching: false, success: true })
      return Object.assign({}, state, {
        [action.payload.category]: newId
      })
    }
    case types.UPLOAD_ID_ISFETCHING: {
      const newId = Object.assign({}, state[action.payload.category], { isFetching: true })
      return Object.assign({}, state, {
        [action.payload.category]: newId
      })
    }
    default:
      return state
  }
}
