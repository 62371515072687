import React from 'react'
import { Label as RsLabel } from 'reactstrap'

import './styles.css'

const Label = (props) => {
  let className = 'Label'
  if (props.floating) className += ' Label-floating'

  return (
    <RsLabel className={className}>
      {props.children}
    </RsLabel>
  )
}

export default Label
