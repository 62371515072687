import pad from 'pad-left'

import { toSmallestDenomination } from 'lib/convert'

const tokenFunctionSignatures = {
  balanceOf: '0x70a08231',
  transfer: '0xa9059cbb'
}

export function tokenSendData (address, amount, decimals) {
  amount = toSmallestDenomination(amount, decimals)

  const dataAddress = pad(address.toLowerCase().replace('0x', ''), 64, '0')
  const dataAmount = pad(amount.toString(16), 64, '0')
  return tokenFunctionSignatures.transfer + dataAddress + dataAmount
}

export function providerData () {
  const providerData = {
    isAvailable: false,
    isEdge: false,
    isWeb3: false,
    isMetaMask: false,
    isCoinbase: false,
    isTrust: false,
    isStatus: false
  }
  if (window.edgeProvider) {
    providerData.isAvailable = true
    providerData.provider = window.edgeProvider
    providerData.type = 'window.edgeProvider'
    providerData.isEdge = true
    providerData.name = 'Edge'
  /* } else if (window.ethereum) { */
  /*   providerData.isAvailable = true */
  /*   providerData.isWeb3 = true */
  /*   providerData.provider = window.ethereum */
  /*   providerData.type = 'window.ethereum' */
  /* } else if (window.web3) { */
  /*   providerData.isAvailable = true */
  /*   providerData.isWeb3 = true */
  /*   providerData.provider = window.web3.currentProvider */
  /*   providerData.type = 'window.web3.currentProvider' */
  /*   providerData.version = (window.web3.version || {}).api */
  }
  const p = providerData.provider || {}
  providerData.isMetaMask = p.isMetaMask
  providerData.isCoinbase = p.isCoinbase || p.isToshi
  providerData.isTrust = p.isTrust
  providerData.isStatus = p.isStatus
  if (providerData.isMetaMask) providerData.name = 'MetaMask'
  if (providerData.isCoinbase) providerData.name = 'Coinbase'
  if (providerData.isTrust) providerData.name = 'Trust'
  if (providerData.isStatus) providerData.name = 'Status'
  return providerData
}
