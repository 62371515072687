import * as types from '../actions/actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MQ:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}
