import React from 'react'

import './styles.css'

const Loader = (props) => {
  if (props.fullPage) {
    let containerClassName = 'Loader-full-page'
    if (props.opaque) containerClassName += ' Loader-opaque'
    return (
      <div className={containerClassName}>
        {!!props.message && <strong style={{ marginBottom: 20 }}>{props.message}</strong>}
        <div className='Loader-spin' />
      </div>
    )
  }
  return (
    <div className='Loader'>
      <div className='Loader-spin-container'>
        <div className='Loader-spin' />
      </div>
    </div>
  )
}

export default Loader
