import React from 'react'
import { translate } from 'react-i18next'
import styled from 'tailwind-styled-components'

/* import { Form } from 'reactstrap' */
/* import { reduxForm, Field } from 'redux-form' */
/* import ReactCodeInput from 'react-code-input' */

/* import FormsInput from 'components/Forms/Input' */
/* import FormsSubmit from 'components/Forms/Submit' */
/* import { validateVerificationCodeForm } from 'lib/validations' */
/* import Button from 'components/Button' */

const OperatorSelectContainer = styled.div`
  @px-5
  @py-6
  @mt-1
  @overflow-y-auto
  @h-full
  @bg-gray-100
  @bg-opacity-75
  @rounded-lg
`

const OperatorSelectButton = styled.button`
  @bg-white
  @border
  @border-gray-400
  @rounded-sm
  @px-10
  @py-4
  @mb-3.5
  @tracking-wide
  @font-semibold
  @text-gray-800
  @shadow-xs
  @transition
  @duration-100
  @transform

  hover:@border-orange-500
  hover:@rounded-none
  hover:@shadow-none
  hover:@translate-x-1.5
`

const Chevron = styled.span`
  @pl-2.5
  @opacity-50
`

const OperatorSelect = translate()((props) => {
  const { handleSelect, operatorList, submitting } = props
  return (
    <OperatorSelectContainer>
      {operatorList.map((operator, ix) => (
        <div key={ix}>
          <OperatorSelectButton disabled={submitting} onClick={() => { handleSelect(operator.operator_id) }}>
            {operator.operator_name || operator.operator_id} <Chevron>›</Chevron>
          </OperatorSelectButton>
        </div>
      ))}
    </OperatorSelectContainer>
  )
})

export default OperatorSelect
