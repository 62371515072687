import React from 'react'
import { Form, FormGroup, Label } from 'reactstrap'
import { translate } from 'react-i18next'
import { reduxForm, Field } from 'redux-form'
import Recaptcha from 'react-recaptcha'

import config from 'config'
import { validatePhoneNumberForm } from 'lib/validations'

import FormPhone from 'components/Forms/Phone'
import FormSubmit from 'components/Forms/Submit'

const MobileNumberForm = reduxForm({
  validate: validatePhoneNumberForm
})(translate()((props) => {
  const {
    handleSubmit,
    submitting,
    btnSubmitting,
    onRecaptchaReady,
    onRecaptchaVerify,
    disabled,
    t,
    countryCode,
    setRecaptchaInstance,
    heading
  } = props
  const instructions = t('identity.instructions', {
    defaultValue: 'Please enter your mobile number. You\'ll receive a verification code via text message to your mobile device.'
  })
  const inputLabel = t('identity.inputLabel', {
    defaultValue: 'Enter your mobile number'
  })
  const submitBtn = t('identity.submitButton', {
    defaultValue: 'Get verification code'
  })

  return (
    <Form onSubmit={handleSubmit} className='@p-4 @pt-0 @w-full'>
      <FormGroup>
        <Label className='@m-auto @text-left @text-lg @text-gray-700 @font-normal @leading-7 @tracking-tight @pb-5'>{heading || instructions}</Label>
      </FormGroup>
      <Field
        name='mobileNumber'
        component={FormPhone}
        label={inputLabel}
        defaultCountry={countryCode || 'US'}
      />
      <FormSubmit id='mobileNumberSubmit' text={submitBtn} submitting={submitting || btnSubmitting} disabled={disabled} spinner />
      <div style={{ display: 'flex', justifyContent: 'center' }} className='@p-4 @pt-3'>
        <Recaptcha
          ref={setRecaptchaInstance}
          render='explicit'
          sitekey={config.recaptcha}
          size='invisible'
          badge='bottomleft'
          onloadCallback={onRecaptchaReady}
          verifyCallback={onRecaptchaVerify}
        />
      </div>
    </Form>
  )
}))

export default MobileNumberForm
