import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_ASSET:
      return initialState
    case types.SET_ASSET:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}
