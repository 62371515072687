import React from 'react'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { FormGroup, FormFeedback } from 'reactstrap'
import Label from 'components/Forms/Label'

import 'react-phone-number-input/style.css'
import './styles.css'

const Phone = (props) => {
  // let validationState = null
  // if (props.meta.submitFailed && props.meta.touched && props.meta.error) {
  //   validationState = 'error'
  // }
  const hasError = props.meta.submitFailed && props.meta.touched && !!props.meta.error
  let className = props.customClass || 'form-control tel-adjust'
  if (hasError) className += ' is-invalid'
  return (
    <FormGroup className='Phone'>
      <Label floating={!props.noFloatingLabel}>
        {props.label}
      </Label>
      <PhoneInput
        defaultCountry={props.defaultCountry}
        className={`${className} @flex`}
        flags={flags}
        value={props.input.value}
        onChange={props.input.onChange}
      />
      <FormFeedback>{props.meta.error}</FormFeedback>
    </FormGroup>
  )
}

export default Phone
