import queryString from 'querystring'

import { get } from './fetch'
import analytics from 'lib/analytics'

import config from 'config'

export const queryLocations = (query = {}, options = {}) => async dispatch => {
  let path = '/location'
  if (config.isDev || config.isRc) query.include = 'is_test_machine'
  if (config.wl) query.operator_id = config.wl.organization_id
  if (Object.keys(query).length) path += `?${queryString.stringify(query)}`
  let data
  try {
    data = await dispatch(get(path, options))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('queryLocations', e.message)
    throw e
  }
  return data.result
}

export const getLocationByMachineId = (id, options = {}) => async (dispatch) => {
  const path = `/location/${id}`
  let data
  try {
    data = await dispatch(get(path, options))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
    if (config.wl && data.result.operator_id !== config.wl.organization_id) throw new Error('Wrong organization')
  } catch (e) {
    analytics.trackError('getLocationByMachineId', e.message)
    throw e
  }
  return data.result
}

export const getLocationSellInfo = (id, query = {}, options = {}) => async dispatch => {
  let path = `/location/${id}/sell`
  if (Object.keys(query).length) path += `?${queryString.stringify(query)}`
  let data
  try {
    data = await dispatch(get(path, options))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
    if (config.wl && data.result.operator_id !== config.wl.organization_id) throw new Error('Wrong organization')
  } catch (e) {
    analytics.trackError('getLocationSellInfo', e.message)
    throw e
  }
  return data.result
}

export const getLocationSellAvailableAmounts = (id, amount, query = {}, options = {}) => async dispatch => {
  let path = `/location/${id}/sell/withdrawal-amount/${amount}`
  if (typeof query.include === 'string' && query.include.includes('alternates') && (config.isDev || config.isRc)) query.alternates_include = 'is_test_machine'
  if (Object.keys(query).length) path += `?${queryString.stringify(query)}`
  let data
  try {
    data = await dispatch(get(path, options))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('getLocationSellAvailableAmounts', e.message)
    throw e
  }
  return data.result
}

export const getLocationSellPromoCode = (id, code, options = {}) => async dispatch => {
  let path = `/location/${id}/sell/promo-code/${code}`
  let data
  try {
    data = await dispatch(get(path, options))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    let errorCode
    console.error(e)
    analytics.trackError('getLocationSellPromoCode', e.message)
    if (e.name === 'AbortError') throw e
    switch (e.message) {
      case 'invalid promo code':
        errorCode = 'promoCode_invalid'
        break
      case 'expired promo code':
      case 'promo code no longer available':
        errorCode = 'promoCode_expired'
        break
      case 'already redeemed promo code':
        errorCode = 'promoCode_redeemed'
        break
      case 'wrong trade type on promo code':
        errorCode = 'promoCode_invalidSell'
        break
      default:
        errorCode = 'promoCode_generic'
    }
    throw new Error(errorCode)
  }
  return data.result
}

export const getLocationRefundInfo = (id, options = {}) => async dispatch => {
  let path = `/location/${id}/refund`
  let data
  try {
    data = await dispatch(get(path, options))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
    if (config.wl && data.result.operator_id !== config.wl.organization_id) throw new Error('Wrong organization')
  } catch (e) {
    analytics.trackError('getLocationRefundInfo', e.message)
    throw e
  }
  return data.result
}
