import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { translate, Trans } from 'react-i18next'
import { Parallax } from 'react-spring'
import { push } from 'react-router-redux'

import { AppContext } from 'contexts'

import MobileNumberForm from './MobileNumberForm'
import VerificationForm from './VerificationForm'
import OperatorSelect from './OperatorSelect'

import analytics from 'lib/analytics'
import { isSafariIos } from 'lib/misc'
import { endAuth, setAccount, setAuthConfirmedAt } from 'actions'
import { sendNonce, checkNonce, operators, updateToken } from 'actions/requests/client'
import config from 'config'

import './styles.css'

const AuthPage = ({ offset, children }) => (
  <Parallax.Layer className='@absolute' offset={offset} speed={0}>
    {children}
  </Parallax.Layer>
)

class Auth extends Component {
  constructor (props) {
    super(props)

    this.state = {
      show: false,
      confirm: false,
      authKey: '',
      customerId: '',
      mobileNum: '',
      mobileNumSubmitting: false,
      mobileNumBtnDisabled: true,
      showVerification: false,
      operatorList: [],
      operatorSelectSubmitting: false
    }

    this.isSafariIos = isSafariIos()

    this.toggle = this.toggle.bind(this)
    this.enableMobileNumberBtn = this.enableMobileNumberBtn.bind(this)
    this.mobileNumberComplete = this.mobileNumberComplete.bind(this)
    this.handleNonceSubmit = this.handleNonceSubmit.bind(this)
    this.closeVerificationModal = this.closeVerificationModal.bind(this)
    this.handleRecaptchaVerify = this.handleRecaptchaVerify.bind(this)
    this.handleMobileNumberSubmit = this.handleMobileNumberSubmit.bind(this)
    this.handleOperatorSelect = this.handleOperatorSelect.bind(this)
    this.scroll = this.scroll.bind(this)
    this.handleApproveIdentity = this.handleApproveIdentity.bind(this)
    this.handleConfirmIdentity = this.handleConfirmIdentity.bind(this)
    this.handleDenyIdentity = this.handleDenyIdentity.bind(this)
    this.done = this.done.bind(this)
  }

  componentDidUpdate (prevProps) {
    const account = this.props.account
    if (!prevProps.account.authRunning && account.authRunning) {
      if (
        account.client_id &&
        account.selectedOperator === account.operator_id &&
        !account.alwaysConfirm &&
        account.confirmedAt &&
        Date.now() - account.confirmedAt < config.authReconfirmTime
      ) {
        return this.done()
      }
      analytics.track('[Auth] Show Auth modal', {
        current: account.phone_number
      })
      this.setState({ show: true, confirm: !!account.client_id })
    }
    if (prevProps.account.authRunning && !account.authRunning) {
      this.setState({ show: false })
    }

    // Browser back button triggered, modal should be hidden
    window.onpopstate = e => {
      this.props.endAuth()
    }
  }

  toggle () {
    if (this.state.mobileNumBtnSubmitting) return

    this.props.endAuth()
  }

  enableMobileNumberBtn () {
    this.setState({ mobileNumBtnDisabled: false, mobileNumSubmitting: false })
  }

  mobileNumberComplete (mobileNumber, recaptcha) {
    const t = this.props.t
    const account = this.props.account
    const options = {}
    if (account.oldTel) options.oldTel = account.oldTel
    analytics.track('[Auth] Submit mobile number form', { mobileNumber, operator: account.selectedOperator })
    this.props.sendNonce(account.selectedOperator, mobileNumber, recaptcha, options)
      .then(result => {
        this.setState({
          mobileNumSubmitting: false,
          mobileNum: result.code_sent_to,
          authKey: result.key
        })
        this.scroll(1)
      })
      .catch((err) => {
        console.error(err)
        switch (err.message) {
          case 'sendNonce_support':
            toastr.confirm(t('alert.support', { defaultValue: 'Sorry, we are unable to complete your request. Please contact support@bitaccess.co for further assistance.' }), { disableCancel: true })
            break
          case 'sendNonce_unrecognized':
            toastr.confirm(t('alert.unrecNumber', { defaultValue: 'Please use the same mobile number used at the BTM. Contact support@bitaccess.co for further assistance.' }), { disableCancel: true })
            break
          case 'sendNonce_invalid':
            toastr.error(t('alert.invalidNumber', { defaultValue: 'Please check the number and try again.' }))
            break
          case 'sendNonce_403':
            window.location.reload()
            break
          case 'sendNonce_not_primary':
            toastr.confirm(t('alert.notPrimary', { defaultValue: 'This mobile number can no longer be used. Please use your primary contact number.' }), { disableCancel: true })
            break
          case 'sendNonce_not_customer':
            toastr.confirm(t('alert.notCustomer', { defaultValue: `We weren't able to find an account for this phone number. Please choose a location to get started.` }), {
              disableCancel: true,
              onOk: () => {
                this.props.endAuth()
                this.props.historyPush('/locations')
              }
            })
            break
          default:
            toastr.error(t('alert.generic', { defaultValue: 'Sorry, there was a problem with your request' }))
        }
        this.recaptchaInstance.reset()
        this.enableMobileNumberBtn()
      })
  }

  handleNonceSubmit (values) {
    analytics.track('[Auth] Submit verification code form')
    const { t, account } = this.props
    const { mobileNum, authKey } = this.state

    const options = {}
    if (account.oldTel) options.oldTel = account.oldTel
    return this.props.checkNonce(account.selectedOperator, mobileNum, authKey, values.code, options)
      .then(result => {
        if (result.restriction === 'operator-id-lookup') {
          return this.props.operators()
        }
        return Promise.resolve()
      })
      .then(operatorList => {
        if (operatorList) {
          this.setState({ operatorList })
          return this.scroll(2)
        }
        this.handleApproveIdentity()
      })
      .catch((err) => {
        switch (err.message) {
          case 'checkNonce_invalid':
            toastr.error(t('alert.invalidNonce', { defaultValue: 'Incorrect code. Please check the verification code and try again.' }))
            break
          case 'checkNonce_reset':
            toastr.confirm(t('alert.support', { defaultValue: 'Sorry, we are unable to complete your request. Please contact support@bitaccess.co for further assistance.' }), {
              disableCancel: true,
              onOk: () => {
                this.props.historyPush('/account/signout')
              }
            })
            break
          default:
            toastr.error(t('alert.generic', { defaultValue: 'Sorry, there was a problem with your request' }))
        }
      })
  }

  async handleOperatorSelect (operator) {
    const { t } = this.props
    this.setState({ operatorSelectSubmitting: true })
    try {
      await this.props.updateToken(operator)
    } catch (err) {
      return toastr.error(t('alert.generic', { defaultValue: 'Sorry, there was a problem with your request' }))
    }
    this.handleApproveIdentity()
  }

  closeVerificationModal () {
    this.setState({ mobileNum: '', authKey: '', showVerification: false })
  }

  handleRecaptchaVerify (response) {
    this.mobileNumberComplete(this.state.mobileNum, response)
  }

  handleMobileNumberSubmit (values) {
    this.setState({ mobileNum: values.mobileNumber, mobileNumSubmitting: true })
    if (config.disableRecaptcha) return this.mobileNumberComplete(values.mobileNumber, 'disabled')
    this.recaptchaInstance.execute()
  }

  scroll (to) {
    this.setState({ showingPage: to })
    this.refs.parallax.scrollTo(to)
  }

  handleDenyIdentity () {
    analytics.track('[Auth] Pressed "Not my phone number"')
    const location = this.props.location
    const currPath = location.pathname + location.search
    this.props.endAuth()
    this.props.historyPush(`/account/signout?next=${currPath}`)
  }

  handleApproveIdentity () {
    analytics.track('[Auth] Approved identity')
    this.props.setAuthConfirmedAt(Date.now())
    this.done()
  }

  handleConfirmIdentity () {
    const { account } = this.props
    analytics.track('[Auth] Pressed "Yes, continue"')
    if (account.selectedOperator !== account.operator_id) {
      return this.handleOperatorSelect(account.selectedOperator)
    }
    this.handleApproveIdentity()
  }

  done () {
    const account = this.props.account
    this.props.setAccount({...account, authRunning: false})
    this.props.endAuth()
    if (account.onContinue) this.props.historyPush(account.onContinue)
  }

  render () {
    const { account, geo, loc } = this.props
    const bodyStyle = {}
    if (!this.state.confirm) {
      bodyStyle.position = 'relative'
      bodyStyle.height = 340
    }
    const cName = config.wl ? config.wl.display_name : config.brandName
    let mobileNumberHeading = ''
    if (account.prepopulate && account.onContinue) {
      if (account.onContinue.includes('account/phone')) {
        mobileNumberHeading = (
          <Trans i18nKey='auth.mobileNumberHeadingOld' parent='span'>
            Please enter your <strong>old</strong> mobile number. You'll receive a verification code via text message to your mobile device.
          </Trans>
        )
      } else if (account.onContinue.includes('change-phone-number/id')) {
        mobileNumberHeading = (
          <Trans i18nKey='auth.mobileNumberHeadingNew' parent='span'>
            Please enter your <strong>new</strong> mobile number. You'll receive a verification code via text message to your mobile device.
          </Trans>
        )
      }
    }

    return (
      <>
        <Modal id='auth-modal' isOpen={this.state.show} toggle={this.toggle} className='@border-none'>
          {/* paddingLeft: '1.43rem',  */}
          <ModalHeader toggle={this.toggle} className='@p-3 @pl-6 @ml-1 @pt-0 @block @text-left' style={{ borderBottom: 'none', paddingTop: '2rem', paddingBottom: '0rem' }}>
            {(!this.state.confirm &&
              <>
                {(!this.state.operatorList.length &&
                <Trans i18nKey='identity.title' parent='span' className='@text-gray-800 @tracking-tight @text-3xl @font-semibold @ml-1 @pt-10'>
                  Verify Your Phone Number
                </Trans>) ||
                <Trans i18nKey='identify.operatorSelect' parent='span' className='@text-gray-800 @tracking-tight @text-3xl @font-semibold @ml-1 @pt-10'>
                  Select Sign-In Operator
                </Trans>
                }
              </>) ||
              <React.Fragment>
                {(account.setFor &&
                  <Trans i18nKey='auth.title' parent='span'>
                    Welcome!
                  </Trans>) ||
                  <Trans i18nKey='identity.confirmTitle' parent='span' className='@text-gray-800 @tracking-tight @text-3xl @font-semibold @ml-1 @pt-10'>
                    Confirm Your Phone Number
                  </Trans>
                }
              </React.Fragment>
            }
          </ModalHeader>
          <ModalBody className='@p-4 @pt-3 @w-full'>
            <div style={bodyStyle}>
              {(!this.state.confirm &&
                <Parallax ref='parallax' pages={3} horizontal scrolling={false}>
                  <AuthPage offset={0}>
                    <MobileNumberForm
                      className='@p-3'
                      form='mobileNumber'
                      heading={mobileNumberHeading}
                      onSubmit={this.handleMobileNumberSubmit}
                      setRecaptchaInstance={e => { this.recaptchaInstance = e }}
                      onRecaptchaReady={this.enableMobileNumberBtn}
                      onRecaptchaVerify={this.handleRecaptchaVerify}
                      disabled={this.state.mobileNumBtnDisabled}
                      btnSubmitting={this.state.mobileNumSubmitting}
                      countryCode={geo.countryCode || loc.country_code || 'US'}
                      initialValues={{ mobileNumber: account.prepopulate }}
                    />
                  </AuthPage>
                  <AuthPage offset={1}>
                    <AppContext.Consumer>
                      {context =>
                        <VerificationForm
                          close={this.closeVerificationModal}
                          mobileNumber={this.state.mobileNum}
                          onSubmit={this.handleNonceSubmit}
                          isSafariIos={context.options.includes('plain_inputs') || this.isSafariIos}
                          showInput={this.state.showingPage === 1}
                        />
                      }
                    </AppContext.Consumer>
                  </AuthPage>
                  <AuthPage offset={2}>
                    <OperatorSelect
                      operatorList={this.state.operatorList}
                      handleSelect={this.handleOperatorSelect}
                      submitting={this.state.operatorSelectSubmitting}
                    />
                  </AuthPage>
                </Parallax>) ||
                <div style={{ margin: 20 }}>
                  {account.setFor === 'id' &&
                    <div>
                      <Trans i18nKey='auth.idContent' parent='p' className='@m-auto @text-left @text-lg @text-gray-700 @font-normal @leading-7 @tracking-tight @pb-5'>
                        You've been directed here by a {{ cName }} BTM to submit identification documents.
                      </Trans>
                    </div>
                  }
                  {account.setFor === 'sell' &&
                    <div>
                      <Trans i18nKey='auth.content' parent='p' className='@m-auto @text-left @text-lg @text-gray-700 @font-normal @leading-7 @tracking-tight @pb-5'>
                        You've been directed here to sell bitcoin to the following machine:
                      </Trans>
                      {loc.machine_id &&
                        <p className='@pb-5'>
                          <strong>{loc.name}</strong><br />
                          <strong>{loc.formatted_address}</strong>
                        </p>
                      }
                    </div>
                  }
                  <Trans i18nKey='auth.content2' parent='p' className='@m-auto @text-left @text-lg @text-gray-700 @font-normal @leading-7 @tracking-tight @pb-5'>
                    Please confirm that the following is your phone number:
                  </Trans>
                  <h3 className='@border-2 @border-dashed @border-gray-300 @bg-gradient-to-br @from-gray-100 @to-gray-200 @text-2xl @font-light @text-gray-700 @text-opacity-75 @tracking-widest @leading-7 @uppercase @text-center @py-3 @mb-9'>
                    {account.phone_number}
                  </h3>
                </div>
              }
            </div>
          </ModalBody>
          {this.state.confirm &&
            <ModalFooter>
              <Button id='authCancel' onClick={this.handleDenyIdentity} className='@w-auto @py-2.5 @text-lg @leading-6 @font-medium @rounded-sm @text-white @bg-white @text-gray-500 hover:@text-white hover:@bg-gray-700 focus:@outline-none @border-none focus:@border-blue-300 focus:@shadow-outline-blue active:@text-gray-800 active:@bg-gray-500 @transition @ease-in-out @duration-150 @transform motion-safe:hover:@-translate-y-10 motion-safe:hover:@scale-110 @transition @tracking-normal'>
                <Trans i18nKey='auth.cancel' parent='span'>
                  Not my phone number!
                </Trans>
              </Button>
              <Button id='authConfirm' onClick={this.handleConfirmIdentity} className='@ml-4 @px-6 @py-2.5 @text-lg @leading-6 @font-bold @rounded-sm @text-white @bg-orange-600 hover:@text-gray-300 focus:@outline-none @border-none focus:@border-blue-300 focus:@shadow-outline-blue active:@text-gray-800 active:@bg-gray-500 @transition @ease-in-out @duration-150 @transform motion-safe:hover:@-translate-y-10 motion-safe:hover:@scale-110 @transition @tracking-normal'>
                <Trans i18nKey='auth.confirm' parent='span'>
                  Yes, continue
                </Trans>
              </Button>
            </ModalFooter>
          }
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  geo: state.geo,
  loc: state.loc
})

const mapDispatchToProps = (dispatch) => ({
  sendNonce: (operatorId, mobileNumber, recaptcha, options) => dispatch(sendNonce(operatorId, mobileNumber, recaptcha, options)),
  checkNonce: (operatorId, mobileNumber, authKey, code, options) => dispatch(checkNonce(operatorId, mobileNumber, authKey, code, options)),
  setAccount: (account) => dispatch(setAccount(account)),
  operators: () => dispatch(operators()),
  updateToken: (operatorId) => dispatch(updateToken(operatorId)),
  endAuth: () => { dispatch(endAuth()) },
  historyPush: (path) => { dispatch(push(path)) },
  setAuthConfirmedAt: (timestamp) => { dispatch(setAuthConfirmedAt(timestamp)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(
  translate()(Auth)
)
