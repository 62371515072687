import React from 'react'
import { FormGroup, FormFeedback, Input, InputGroup, Label } from 'reactstrap'

import './styles.css'

const FormInput = (props) => {
  const inputExtraProps = {}
  if (props.noAutoComplete) inputExtraProps.autoComplete = 'off'
  if (props.step) inputExtraProps.step = props.step
  const hasError = props.meta.submitFailed && props.meta.touched && !!props.meta.error
  if (props.disabled || props.meta.submitting) inputExtraProps.disabled = true

  const onChange = (event) => {
    if (props.customChange) props.customChange(event)
    props.input.onChange(event)
  }

  const inputWrapper = () => {
    if (props.addon) {
      return (
        <InputGroup>
          {input()}
          {props.children}
        </InputGroup>
      )
    }
    return input()
  }

  const input = () => {
    let className = 'FormsInput-input'
    if (props.className) className += ` ${props.className}`
    return (
      <Input
        className={className}
        type={props.type}
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        invalid={hasError}
        {...props.input}
        {...inputExtraProps}
        onChange={onChange}
      >
        {props.inner}
      </Input>
    )
  }

  return (
    <FormGroup className='FormsInput' {...props.formGroupProps}>
      <Label for={props.id} className='FormsInput-label'>{props.label}</Label>
      {inputWrapper()}
      <FormFeedback>{props.meta.error}</FormFeedback>
    </FormGroup>
  )
}

export default FormInput
