import validator from 'validator'
import { isValidNumber } from 'libphonenumber-js'
import { validate as validateCpf } from 'cpf-check'
import i18n from '../i18n'

const CODE_LENGTH = 6
// const MIN_BITCOIN_ADDRESS_LENGTH = 26

const error = (key, value) => {
  switch (key) {
    case 'required':
      return (
        i18n.t('validations.required', {
          defaultValue: 'Required'
        })
      )
    case 'nonNumeric':
      return (
        i18n.t('validations.nonNumeric', {
          defaultValue: 'May only contain numbers'
        })
      )
    case 'nonAlphanumeric':
      return (
        i18n.t('validations.nonAlphanumeric', {
          defaultValue: 'May only contain letters and numbers'
        })
      )
    case 'codeLength':
      return (
        i18n.t('validations.codeLength', {
          number: CODE_LENGTH,
          defaultValue: 'Code should be {{number}} digits in length'
        })
      )
    case 'invalidPhone':
      return (
        i18n.t('validations.invalidPhone', {
          defaultValue: 'Please enter a valid phone number'
        })
      )
    case 'amount':
      return (
        i18n.t('validations.amount', {
          defaultValue: 'Amount is too low'
        })
      )
    case 'invalidBtc':
      return (
        i18n.t('validations.invalidBtc', {
          defaultValue: 'Invalid bitcoin address'
        })
      )
    case 'email': {
      return (
        i18n.t('validations.email', {
          defaultValue: 'Please enter a valid email address'
        })
      )
    }
    case 'integer': {
      return (
        i18n.t('validations.integer', {
          defaultValue: 'Must be an integer'
        })
      )
    }
    case 'min': {
      return (
        i18n.t('validations.minimum', {
          defaultValue: 'Minimum: {{ minimum }}',
          minimum: value
        })
      )
    }
    case 'max': {
      return (
        i18n.t('validations.maximum', {
          defaultValue: 'Maximum: {{ maximum }}',
          maximum: value
        })
      )
    }
    case 'minMax': {
      return (
        i18n.t('validations.minMax', {
          defaultValue: 'Number must be between: {{ range }}',
          range: value
        })
      )
    }
    case 'notEqual': {
      return (
        i18n.t('validations.notEqual', {
          defaultValue: 'Values must not be the same'
        })
      )
    }
    case 'incompleteAddress': {
      return (
        i18n.t('validations.incompleteAddress', {
          defaultValue: 'Please select your specific street address from the dropdown'
        })
      )
    }
    case 'invalidSsn': {
      return (
        i18n.t('validations.invalidSsn', {
          defaultValue: 'Please enter a valid SSN'
        })
      )
    }
    case 'invalidCpf': {
      return (
        i18n.t('validations.invalidCpf', {
          defaultValue: 'Please enter a valid CPF'
        })
      )
    }
    default:
      console.error('unknown validation key', key)
  }
}

const validate = {
  phoneNumber: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    } else if (!isValidNumber(value)) {
      newErrors[name] = error('invalidPhone')
    }
    return newErrors
  },
  code: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    } else if (!validator.isNumeric(value)) {
      newErrors[name] = error('nonNumeric')
    } else if (!validator.isLength(value, { min: options.length, max: options.length })) {
      newErrors[name] = error('codeLength')
    }
    return newErrors
  },
  amount: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    } else if (!validator.isFloat(value, { gt: 0 })) {
      newErrors[name] = error('amount')
    }
    return newErrors
  },
  bitcoinAddress: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    }
    return newErrors
  },
  id: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value || !value.photoFile || !value.photoFile.length || !value.selfieFile || !value.selfieFile.length) {
      newErrors[name] = error('required')
    }
    return newErrors
  },
  email: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    } else if (!validator.isEmail(value || '')) {
      newErrors[name] = error('email')
    }
    return newErrors
  },
  promoCode: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    } else if (!validator.isAlphanumeric(value)) {
      newErrors[name] = error('nonAlphanumeric')
    }
    return newErrors
  },
  number: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (value == null) {
      newErrors[name] = error('required')
    } else if (!validator.isInt(value)) {
      newErrors[name] = error('integer')
    } else if (options && !validator.isInt(value, options)) {
      if ('min' in options && 'max' in options) {
        newErrors[name] = error('minMax', `${options.min} - ${options.max}`)
      } else if ('min' in options) {
        newErrors[name] = error('min', options.min)
      } else if ('max' in options) {
        newErrors[name] = errors('max', options.max)
      } else {
        newErrors[name] = error('integer')
      }
    }
    return newErrors
  },
  notEqual: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (value === options.compare) {
      newErrors[name] = error('notEqual')
    }
    return newErrors
  },
  addressSearch: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    } else if (options.match !== value || !options.isComplete) {
      newErrors[name] = error('incompleteAddress')
    }
    return newErrors
  },
  ssn: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    } else if (!/^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/.test(value)) {
      newErrors[name] = error('invalidSsn')
    }
    return newErrors
  },
  cpf: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    } else if (!validateCpf(value)) {
      newErrors[name] = error('invalidCpf')
    }
    return newErrors
  },
  required: (value, name, options, errors) => {
    const newErrors = Object.assign({}, errors)
    if (!value) {
      newErrors[name] = error('required')
    }
    return newErrors
  }
}

const vProcess = (values, validationList) => {
  return validationList.reduce((acc, curr) => {
    return curr.func(values[curr.name], curr.name, curr.options, acc)
  }, {})
}

export const validatePhoneNumberForm = (values) => vProcess(values, [
  { name: 'mobileNumber', func: validate.phoneNumber }
])
export const validateVerificationCodeForm = (values) => vProcess(values, [
  { name: 'code', func: validate.code, options: { length: CODE_LENGTH } }
])
export const validateAmountForm = (values) => vProcess(values, [
  { name: 'desiredAmount', func: validate.amount }
])
export const validateIDForm = (values) => vProcess(values, [
  { name: 'id', func: validate.id }
])
export const validateOperatorContactForm = (values) => vProcess(values, [
  { name: 'email', func: validate.email },
  { name: 'model', func: validate.required },
  { name: 'message', func: validate.required }
])
export const validateOperatorDepositForm = (values) => {
  const quantityOptions = { min: 1 }
  if (['Bitaccess BTM C', 'Bitaccess BTM SE'].includes(values.model)) quantityOptions.min = 2
  return vProcess(values, [
    { name: 'name', func: validate.required },
    { name: 'email', func: validate.email },
    { name: 'phone', func: validate.required },
    { name: 'model', func: validate.required },
    { name: 'quantity', func: validate.number, options: quantityOptions }
  ])
}
export const validateAddressForm = (values) => vProcess(values, [
  { name: 'address', func: validate.required }
])
export const validatePromoCodeForm = (values) => vProcess(values, [
  { name: 'promoCode', func: validate.promoCode }
])
export const validateChangePhoneNumberForm = (values) => vProcess(values, [
  { name: 'oldMobileNumber', func: validate.phoneNumber },
  { name: 'newMobileNumber', func: validate.phoneNumber },
  { name: 'newMobileNumber', func: validate.notEqual, options: { compare: values.oldMobileNumber } }
])
export const validateAccountDetailsForm = (values) => {
  const checks = []
  if (values.initial_name_first !== values.name_first || values.initial_name_last !== values.name_last) {
    checks.push({ name: 'name_first', func: validate.required })
    checks.push({ name: 'name_last', func: validate.required })
  }
  if (values.initial_email !== values.email) {
    checks.push({ name: 'email', func: validate.email })
  }
  if (values.initial_address_search !== values.address_search || values.initial_address_street !== values.address_street || values.initial_address_city !== values.address_city || values.initial_address_state !== values.address_state || values.initial_address_country !== values.address_country || values.initial_address_postal_code !== values.address_postal_code) {
    const isComplete = !!values.address_street && !!values.address_city && !!values.address_state && !!values.address_country && !!values.address_postal_code
    checks.push({ name: 'address_search', func: validate.addressSearch, options: { match: values.address_formatted, isComplete } })
    checks.push({ name: 'address_street', func: validate.required })
    checks.push({ name: 'address_city', func: validate.required })
    checks.push({ name: 'address_state', func: validate.required })
    checks.push({ name: 'address_country', func: validate.required })
    checks.push({ name: 'address_postal_code', func: validate.required })
  }
  if (values.initial_occupation !== values.occupation) {
    checks.push({ name: 'occupation', func: validate.required })
  }
  switch (values.countries) {
    case 'BR':
      if (values.initial_cpf !== values.cpf) checks.push({ name: 'cpf', func: validate.cpf })
      break
    case 'US':
      if (values.initial_ssn !== values.ssn) checks.push({ name: 'ssn', func: validate.ssn })
      break
    default:
      //
  }
  return vProcess(values, checks)
}
export const validateProofOfAddressForm = (values) => vProcess(values, [
  { name: 'type', func: validate.required },
  { name: 'country_code', func: validate.required }
])
