import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import createExpirationTransform from 'redux-persist-transform-expire'
import { I18nextProvider } from 'react-i18next'
import logger from 'redux-logger'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory as createHistory } from 'history'
import { ConnectedRouter, routerMiddleware } from 'react-router-redux'
import TagManager from 'react-gtm-module'

import 'babel-polyfill'

import i18n from 'i18n'
import rootReducer from 'reducers'
import config from 'config'

import App from 'components/App'
import { unregister } from 'registerServiceWorker'

if (!window.AbortController) {
  window.AbortController = class AbortController {
    abort () {}
  }
}

window.btm = {
  controller: new window.AbortController()
}

const history = createHistory()

const middlewares = [thunkMiddleware, routerMiddleware(history)]

if (config.isDev) middlewares.push(logger)

if (!config.isDev && !config.wl) {
  const tagManagerConfig = {
    gtmId: 'GTM-5P23S2K'
  }
  TagManager.initialize(tagManagerConfig)
}


const persistConfig = {
  key: 'root',
  blacklist: ['i18n', 'mq', 'form', 'toastr', 'account', 'identification', 'faast'],
  storage,
  transforms: [createExpirationTransform({
    defaultState: Object.assign({}, config.initialState)
  })]
}
const store = createStore(
  persistReducer(persistConfig, rootReducer),
  applyMiddleware(...middlewares)
)
const persistor = persistStore(store)

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
)

unregister()
