import * as types from '../actions/actionTypes'

const initialState = {
  isActive: false,
  machineId: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_LEVEL_UP_IFRAME:
      return initialState
    case types.SET_LEVEL_UP_IFRAME:
      return Object.assign({}, state, { isActive: true, machineId: action.payload })
    default:
      return state
  }
}
