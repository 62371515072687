import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState, {
  settings: {},
  location: {},
  org: {},
  cash: {},
  resources: {}
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_SESSION:
    case types.RESET_MACHINE:
      return initialState
    case types.FETCH_LEVELS:
      return Object.assign({}, state, { isFetching: true })
    case types.SET_LEVELS:
      return Object.assign({}, state, { cash: action.payload, isFetching: false })
    case types.SET_MACHINE:
      return Object.assign({}, initialState, {
        settings: action.payload.settings,
        location: action.payload.location || {},
        org: action.payload.org || {},
        id: action.payload.settings && action.payload.settings.machine_id
      })
    case types.SET_MACHINE_RESOURCES:
      return Object.assign({}, state, { resources: action.payload })
    default:
      return state
  }
}
