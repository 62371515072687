import * as types from '../actions/actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_SESSION:
    case types.SET_MACHINE:
    case types.RESET_PROMO:
      return initialState
    case types.SET_PROMO:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}
