import analytics from '../lib/analytics'
import * as types from './actionTypes'

export const resetAll = () => ({
  type: types.RESET_ALL
})

export const i18nReady = () => ({
  type: types.I18N_READY
})

export const setAccount = (account) => {
  if (account.client_id) {
    analytics.identify({
      id: account.client_id,
      phone: account.phone_number
    })
  }
  return {
    type: types.SET_ACCOUNT,
    payload: account
  }
}

export const resetAccount = () => {
  analytics.reset()
  return {
    type: types.RESET_ACCOUNT
  }
}

export const setLocation = (location) => ({
  type: types.SET_LOCATION,
  payload: { location }
})

export const resetLocation = () => ({
  type: types.RESET_LOCATION
})

export const setAsset = (asset) => ({
  type: types.SET_ASSET,
  payload: asset
})

export const resetAsset = () => ({
  type: types.RESET_ASSET
})

export const setMachine = (settings, location, org) => ({
  type: types.SET_MACHINE,
  payload: { settings, location, org }
})

export const resetMachine = () => ({
  type: types.RESET_MACHINE
})

export const setMachineResources = (resources) => ({
  type: types.SET_MACHINE_RESOURCES,
  payload: resources
})

export const fetchGeo = () => ({
  type: types.FETCH_GEO
})

export const setGeo = (payload) => ({
  type: types.SET_GEO,
  payload
})

export const disallowGeo = () => ({
  type: types.DISALLOW_GEO
})

export const fetchPrice = (category) => ({
  type: types.FETCH_PRICE,
  payload: { category }
})

export const fetchPriceClear = (category) => ({
  type: types.FETCH_PRICE_CLEAR,
  payload: { category }
})

export const setPrice = (category, price) => ({
  type: types.SET_PRICE,
  payload: { category, price }
})

export const resetPrice = (category) => ({
  type: types.RESET_PRICE,
  payload: { category }
})

export const priceFetchRequests = (requests) => ({
  type: types.PRICE_FETCH_REQUESTS,
  payload: requests
})

export const setOrder = (order) => ({
  type: types.SET_ORDER,
  payload: order
})

export const updateOrderStatus = (status) => ({
  type: types.UPDATE_ORDER_STATUS,
  payload: status
})

export const setTx = (tx) => ({
  type: types.SET_TX,
  payload: tx
})

export const setRefTx = (tx) => ({
  type: types.SET_REF_TX,
  payload: tx
})

export const resetTx = () => ({
  type: types.RESET_TX
})

export const setMediaQueries = (mq) => ({
  type: types.SET_MQ,
  payload: mq
})

export const setLevelUpProgressSteps = (steps) => ({
  type: types.SET_LEVEL_UP_PROGRESS_STEPS,
  payload: steps
})

export const advanceLevelUpProgressStep = () => ({
  type: types.ADVANCE_LEVEL_UP_PROGRESS_STEP
})

export const resetLevelUpProgressSteps = (index, step) => ({
  type: types.RESET_LEVEL_UP_PROGRESS_STEPS
})

export const fetchLevels = () => ({
  type: types.FETCH_LEVELS
})

export const setLevels = (levels) => ({
  type: types.SET_LEVELS,
  payload: levels
})

export const resetLimits = (limits) => ({
  type: types.RESET_LIMITS
})

export const fetchLimits = () => ({
  type: types.FETCH_LIMITS
})

export const setLimits = (limits) => ({
  type: types.SET_LIMITS,
  payload: limits
})

export const setTimer = (timeout) => ({
  type: types.SET_TIMER,
  payload: timeout
})

export const setSessionStatus = (status) => ({
  type: types.SET_SESSION_STATUS,
  payload: status
})

export const resetSession = () => ({
  type: types.RESET_SESSION
})

export const setRefundAddress = (address) => ({
  type: types.SET_REFUND_ADDRESS,
  payload: address
})

export const resetSurvey = () => ({
  type: types.RESET_SURVEY
})

export const setSurvey = (payload) => ({
  type: types.SET_SURVEY,
  payload
})

export const resetIdentification = () => ({
  type: types.RESET_IDENTIFICATION
})

export const addIdentification = (category, id) => ({
  type: types.ADD_IDENTIFICATION,
  payload: { category, id }
})

export const removeIdentification = (category) => ({
  type: types.REMOVE_IDENTIFICATION,
  payload: { category }
})

export const addIdURL = (category, url) => ({
  type: types.ADD_ID_URL,
  payload: { category, url }
})

export const uploadIdError = (category) => ({
  type: types.UPLOAD_ID_ERROR,
  payload: { category }
})

export const uploadIdSuccess = (category) => ({
  type: types.UPLOAD_ID_SUCCESS,
  payload: { category }
})

export const uploadIsFetching = (category) => ({
  type: types.UPLOAD_ID_ISFETCHING,
  payload: { category }
})

export const startAuth = (options) => ({
  type: types.START_AUTH,
  payload: options
})

export const endAuth = () => ({
  type: types.END_AUTH
})

export const reauthRequired = () => ({
  type: types.REAUTH_REQUIRED
})

export const setAuthConfirmedAt = (timestamp) => ({
  type: types.SET_AUTH_CONFIRMED_AT,
  payload: timestamp
})

export const fetchFaast = () => ({
  type: types.FETCH_FAAST
})

export const setFaastCurrencies = (currencies) => ({
  type: types.SET_FAAST_CURRENCIES,
  payload: currencies
})

export const fetchCurrencies = () => ({
  type: types.FETCH_CURRENCIES
})

export const setCurrencies = (currencies) => ({
  type: types.SET_CURRENCIES,
  payload: currencies
})

export const setTermsRequired = (required) => ({
  type: types.SET_TERMS_REQUIRED,
  payload: required
})

export const setTermsAccepted = (accepted) => ({
  type: types.SET_TERMS_ACCEPTED,
  payload: accepted
})

export const setSellInitiated = () => ({
  type: types.SET_SELL_INITIATED
})

export const setSellAsset = (asset) => ({
  type: types.SET_SELL_ASSET,
  payload: asset
})

export const setSellLocation = (location) => ({
  type: types.SET_SELL_LOCATION,
  payload: location
})

export const setSellTerms = (terms) => ({
  type: types.SET_SELL_TERMS,
  payload: terms
})

export const setSellPromoCodeAccepted = (accepted) => ({
  type: types.SET_SELL_PROMO_CODE_ACCEPTED,
  payload: accepted
})

export const setSellPromo = (promo) => ({
  type: types.SET_SELL_PROMO,
  payload: promo
})

export const setSellMachine = (machineId) => ({
  type: types.SET_SELL_MACHINE,
  payload: machineId
})

export const setSellAmounts = (amounts) => ({
  type: types.SET_SELL_AMOUNTS,
  payload: amounts
})

export const setSellRefundAddress = (address) => ({
  type: types.SET_SELL_REFUND_ADDRESS,
  payload: address
})

export const setSellMinimumWithdrawal = (amount) => ({
  type: types.SET_SELL_MINIMUM_WITHDRAWAL,
  payload: amount
})

export const setFlow = (flow) => ({
  type: types.SET_FLOW,
  payload: flow
})

export const setSellInitialRequest = (providedInitialRequest) => ({
  type: types.SET_SELL_INITIAL_REQUEST,
  payload: providedInitialRequest
})

export const setSellConfirmed = (confirmed) => ({
  type: types.SET_SELL_CONFIRMED,
  payload: confirmed
})

export const setPromo = (promo) => ({
  type: types.SET_PROMO,
  payload: promo
})

export const resetPromo = () => ({
  type: types.RESET_PROMO
})

export const setRefundTransaction = (txn) => ({
  type: types.SET_REFUND_TRANSACTION,
  payload: txn
})

export const setRefundSwap = (swap) => ({
  type: types.SET_REFUND_SWAP,
  payload: swap
})

export const setRefundConfirmed = () => ({
  type: types.SET_REFUND_CONFIRMED
})

export const setRefundSubmitted = () => ({
  type: types.SET_REFUND_SUBMITTED
})

export const resetRefund = () => ({
  type: types.RESET_REFUND
})

export const setProvider = (provider) => ({
  type: types.SET_PROVIDER,
  payload: provider
})

export const setLevelUpIframe = (machineId) => ({
  type: types.SET_LEVEL_UP_IFRAME,
  payload: machineId
})

export const resetLevelUpIframe = (steps) => ({
  type: types.RESET_LEVEL_UP_IFRAME
})
