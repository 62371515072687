import React from 'react'
import { FormGroup, Button } from 'reactstrap'

import './styles.css'

const Submit = (props) => {
  let className = 'Submit'
  if (props.className) className += ` ${props.className}`
  let buttonText = props.text || ''
  const btnClass = props.customClass || ''
  let spinner = ''
  if (props.spinner && props.submitting) {
    spinner = <span>&nbsp;&nbsp;<i className='fas fa-circle-notch fa-pulse @m-auto @fill-current @text-gray-300' /></span>
    buttonText = 'Please wait...'
  }

  return (
    <FormGroup className={`${className} @mt-6 @text-right @border-none`}>
      <Button id={props.id} disabled={props.submitting || props.disabled} className={`${btnClass} @w-full @py-2.5 @text-lg @leading-6 @font-bold @rounded-sm @text-white @bg-orange-600 hover:@text-gray-300 focus:@outline-none @border-none focus:@border-blue-300 focus:@shadow-outline-blue active:@text-gray-800 active:@bg-gray-500 @transition @ease-in-out @duration-150 @transform motion-safe:hover:@-translate-y-10 motion-safe:hover:@scale-110 @transition @tracking-normal`} type='submit'>
        {buttonText}
        {spinner}
      </Button>
    </FormGroup>
  )
}

export default Submit
