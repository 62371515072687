import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ReduxToastr from 'react-redux-toastr'
import { connect } from 'react-redux'
import queryString from 'querystring'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'index.css'
import 'media/css/tailwind.min.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import '@fortawesome/fontawesome-free/css/all.css'

import { AppContext } from 'contexts'

import withLogger from 'components/Logger'
import withScrollToTop from 'components/ScrollToTop'
import Loader from 'components/Loader'
import Auth from 'components/Auth'

import Analytics from 'lib/analyticsServices'
import analytics from 'lib/analytics'
import { setMediaQueries, startAuth, setLocation, setLevelUpIframe } from 'actions'
import { getCurrencies } from 'actions/requests/currency'
import { getLocationByMachineId } from 'actions/requests/location'
import { getAccount, ott } from 'actions/requests/client'
import config from 'config'
import { providerData } from 'lib/walletProvider'

const Home = lazy(() => import('components/Home'))
const Resources = lazy(() => import('components/Resources'))
const Software = lazy(() => import('components/Software'))
const Locations = lazy(() => import('components/Locations'))
const Operators = lazy(() => import('components/Operators'))
const FormReceived = lazy(() => import('components/FormReceived'))
const CashAPI = lazy(() => import('components/CashAPI'))
const Sell = lazy(() => import('components/Sell'))
const Refund = lazy(() => import('components/Refund'))
const Account = lazy(() => import('components/Account'))
const PrivateRoute = lazy(() => import('components/PrivateRoute'))
const Altcoins = lazy(() => import('components/Altcoins'))
const GetAWallet = lazy(() => import('components/GetAWallet'))
const PhoneChange = lazy(() => import('components/PhoneChange'))
const Callback = lazy(() => import('components/Callback'))
const PrivacyPolicy = lazy(() => import('components/PrivacyPolicy'))
const TermsAndConditions = lazy(() => import('components/TermsAndConditions'))
const HardwareTerms = lazy(() => import('components/HardwareTerms'))
const OperatorOnboarding = lazy(() => import('components/OperatorOnboarding'))
const SubProcessors = lazy(() => import('components/SubProcessors'))
const Blog = lazy(() => import('components/Blog'))
const BTMTermsAndConditions = lazy(() => import('components/BTMTermsAndConditions'))

const widths = new Map()
widths.set('sm', '(min-width: 576px)')
widths.set('md', '(min-width: 768px)')
widths.set('lg', '(min-width: 992px)')
widths.set('xl', '(min-width: 1200px)')

const loaderInitialState = {
  showLoader: false,
  loaderLocked: false,
  loaderOpaque: false,
  loaderMessage: false
}

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ready: false,
      geoSuggest: {},
      showTestLocations: config.isDev || config.isRc,
      options: [],
      initialCountry: '',
      ...loaderInitialState
    }

    props.history.listen((location, action) => {
      if (action === 'PUSH') {
        if (!this.state.loaderLocked) {
          this.setState({ ...loaderInitialState })
        }
      }
    })

    this.mediaQueryChange = this.mediaQueryChange.bind(this)
  }

  componentWillMount () {
    window.btm.analytics = new Analytics(config.analytics)

    const tokenCheck = () => {
      let search = this.props.location.search
      if (search.charAt(0) === '?') search = this.props.location.search.slice(1)
      const query = queryString.parse(search)

      if (query.allow) {
        const allowList = query.allow.split(',')
        if (allowList.includes('test_machine')) this.setState({ showTestLocations: true })
      }
      if (query.options) {
        const optionsList = query.options.split(',')
        this.setState({ options: optionsList })
      }
      if (query.country) {
        this.setState({ initialCountry: query.country })
      }
      if (query.r_token) {
        let ottResult
        this.props.ott(query.r_token)
          .then(result => {
            ottResult = result
            const machineId = result.machine_id
            const isLevelUpIframe = (result.type === 'level-up-iframe')
            if (machineId && isLevelUpIframe) {
              return this.props.setLevelUpIframe(machineId)
            }
            if (machineId) {
              return this.props.getLocationByMachineId(machineId)
            }
            return Promise.resolve()
          })
          .then(location => {
            if (location) {
              this.props.setLocation(location)
            }
            this.setState({ ready: true })
            if (ottResult.type === 'sell') {
              delete query.r_token
              this.props.startAuth({ onContinue: `/sell?${queryString.stringify(query)}`, selectedOperator: ottResult.operator_id })
            }
          })
          .catch((err) => {
            console.error(err)
            this.setState({ ready: true })
          })
      } else {
        this.setState({ ready: true })
      }
    }

    this.props.getAccount()
      .then(() => {
        tokenCheck()
      })
      .catch(e => {
        console.error(e)
        tokenCheck()
      })

    this.props.getCurrencies().catch(console.error)
  }

  componentDidMount () {
    analytics.track('mounted')
    window.setTimeout(() => {
      if (providerData().isEdge) {
        this.props.setProvider({ isEdge: true })
      }
    }, 5000)

    const match = {}
    widths.forEach((value, key) => {
      match[key] = window.matchMedia(value).matches
      window.matchMedia(value).addListener((e) => { this.mediaQueryChange(e, key) })
    })
    this.props.setMediaQueries(match)
  }

  componentDidUpdate (prevProps, prevState) {
    const toastrs = this.props.toastr.toastrs
    if (!prevProps.toastr.toastrs.length && toastrs.length) {
      const alert = toastrs[toastrs.length - 1]
      analytics.track('Show Alert', {
        type: alert.type,
        title: alert.title,
        message: alert.message
      })
    }
    if (!prevProps.toastr.confirm && this.props.toastr.confirm) {
      analytics.track('Show Alert', {
        type: 'confirm',
        message: this.props.toastr.confirm.message
      })
    }
  }

  mediaQueryChange (mq, type) {
    this.props.setMediaQueries({ [type]: mq.matches })
  }

  render () {
    const txn = this.props.transaction
    if (!this.state.ready) {
      // duplicate of one in index.html for consistency
      return (
        <div style={{ height: 64, width: 64, margin: 'auto', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
          <i className='fas fa-circle-notch fa-pulse fa-2x @text-gray-300' />
        </div>
      )
    } else {
      return (
        <div className='app-body' style={{ height: '100%' }}>
          {config.isDev &&
            <div id='internal-data' style={{ zIndex: 1000, position: 'relative' }}>
              <div id='internal-machine-id' data={txn.machine} />
              <div id='internal-transaction-id' data={txn.transaction_num} />
              <div id='debug-bar' style={{
                height: 30,
                backgroundColor: 'grey',
                padding: 5,
                color: 'white',
                display: config.debugBar ? 'flex' : 'none'
              }}>
                <span>DEBUG BAR |&nbsp;</span>
                <span>MACHINE: {txn.machine_id} |&nbsp;</span>
                <span>TRANSACTION: {txn.transaction_num}</span>
              </div>
            </div>
          }
          <AppContext.Provider value={{
            geoSuggest: this.state.geoSuggest,
            setGeoSuggest: (geoSuggest) => this.setState({ geoSuggest }),
            loaderShowing: this.state.showLoader,
            showLoader: (options = {}) => {
              const newState = { showLoader: true }
              if (options.lock) newState.loaderLocked = true
              if (options.opaque) newState.loaderOpaque = true
              if (options.message) newState.loaderMessage = options.message
              this.setState(newState)
            },
            hideLoader: () => this.setState({ ...loaderInitialState }),
            showTestLocations: this.state.showTestLocations,
            options: this.state.options,
            initialCountry: this.state.initialCountry,
            resetInitialCountry: () => this.setState({ initialCountry: '' })
          }}>
            <Suspense fallback={null}>
              <Auth {...this.props} />
              <Switch>
                {((config.wl || config.theme === 'lean') &&
                  <Route exact path='/' component={Locations} />
                ) ||
                  <Route exact path='/' render={props => (
                    <Home title='Bitaccess: The enterprise solution for Bitcoin ATM operations' {...props} />
                  )} />
                }
                {!config.wl &&
                  <Route path='/operators' render={props => (
                    <Operators
                      title='Operators'
                      description='Bitaccess offers the turnkey solution for starting a Bitcoin ATM operation. Launch, deploy, and scale a compliant BTM Fleet.'
                      {...props}
                    />
                  )} />
                }
                {!config.wl &&
                  <Route path='/c-operators' render={props => (
                    <Operators
                      title='Operators'
                      description='Bitaccess offers the turnkey solution for starting a Bitcoin ATM operation. Launch, deploy, and scale a compliant BTM Fleet.'
                      hubspotFormId={config.hubspot.coinAtmRadarFormId}
                      {...props}
                    />
                  )} />
                }
                {!config.wl &&
                  <Route path='/retrofit' render={props => (
                    <Operators
                      restrictToOneProduct='retrofit'
                      hubspotFormId={config.hubspot.retrofitFormId}
                      title='Retrofit'
                      description='Bitaccess offers the turnkey solution for starting a Bitcoin ATM operation. Launch, deploy, and scale a compliant BTM Fleet.'
                      {...props}
                    />
                  )} />
                }
                <Route path='/cash-api' render={props => (
                  <CashAPI
                    title='Cash API'
                    description='Built on Bitaccess’s global BTM network, you can securely enable your customers to instantly convert their cryptocurrency to cash. Whether you’re a DeFi app developer, an exchange, or an E-Bank.'
                    {...props}
                  />
                )} />
                <Route path='/resources' render={props => (
                  <Resources
                    title='Resources'
                    description='Resources on starting a Bitcoin ATM operation. Learn more about BTM Kiosks and software through our frequently asked questions.'
                    {...props}
                  />
                )} />
                <Route path='/software' render={props => (
                  <Software
                    title='Software'
                    description='Bitaccess Bitcoin ATM Software suite simplifies launching, management and maintenance of Bitcoin ATM fleets at scale.'
                    {...props}
                  />
                )} />
                <Route path='/locations' render={props => (
                  <Locations
                    title='Locations'
                    description='Find Locations of Bitaccess Bitcoin ATMs near you. Easily discover BTM Locations to buy and sell cryptocurrency.'
                    {...props}
                  />
                )} />
                <Route path='/blog' render={props => (
                  <Blog {...props} />
                )} />
                <Route path='/form/received' render={props => (
                  <FormReceived title='Got it! - Thank You' {...props} />
                )} />
                <Route path='/sell' render={(props) => (
                  <PrivateRoute path={props.match.path} component={Sell} {...props} />
                )} />
                <Route path='/operator/onboarding' render={props => (
                  <OperatorOnboarding title='Operator Onboarding' {...props} />
                )} />
                <Route path='/refund' component={Refund} />
                <Route path='/account' component={Account} />
                {/* <Redirect from='/altcoins' to='/digital-currencies' />
                <Route path='/digital-currencies' render={props => (
                  <Altcoins title='Supported Digital Currencies' {...props} />
                )} /> */}
                <Route path='/get-a-wallet' render={props => (
                  <GetAWallet title='Get A Wallet' {...props} />
                )} />
                <Route path='/change-phone-number' render={props => (
                  <PhoneChange title='Change Phone Number' {...props} />
                )} />
                <Route path='/privacy-policy' render={props => (
                  <PrivacyPolicy title='Privacy Policy' {...props} />
                )} />
                <Route path='/sub-processors' render={props => (
                  <SubProcessors title='Sub Processors' {...props} />
                )} />
                <Route path='/sms-terms' render={props => (
                  <TermsAndConditions title='Bitaccess Mobile Terms of Service' {...props} />
                )} />
                <Route path='/hardware-terms' render={props => (
                  <HardwareTerms title='Bitaccess Hardware Purchase Terms' {...props} />
                )} />
                <Route path='/btm-terms' 
                  render={props =>(
                    <BTMTermsAndConditions title='Bitcoin Depot Terms and Conditions' {...props}/>
                  )}
                />
                <Route path='/callback' component={Callback} />
                <Redirect to='/' />
              </Switch>
            </Suspense>
          </AppContext.Provider>
          <ReduxToastr
            timeOut={6000}
            position='bottom-right'
            newestOnTop={false}
            progressBar
          />
          {this.state.showLoader && <Loader fullPage opaque={this.state.loaderOpaque} message={this.state.loaderMessage} />}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  transaction: state.transaction,
  toastr: state.toastr,
  provider: state.provider
})

const mapDispatchToProps = (dispatch) => ({
  setMediaQueries: (mq) => { dispatch(setMediaQueries(mq)) },
  getAccount: () => dispatch(getAccount()),
  startAuth: (options) => { dispatch(startAuth(options)) },
  getCurrencies: () => dispatch(getCurrencies()),
  ott: (token) => dispatch(ott(token)),
  getLocationByMachineId: (id) => dispatch(getLocationByMachineId(id)),
  setLocation: (loc) => { dispatch(setLocation(loc)) },
  setLevelUpIframe: (machineId) => { dispatch(setLevelUpIframe(machineId)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withLogger()(withScrollToTop()(App))
)
