import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_SESSION:
    case types.SET_MACHINE:
      return initialState
    case types.SET_ORDER:
      return Object.assign({}, state, action.payload)
    case types.UPDATE_ORDER_STATUS:
      return Object.assign({}, state, { status: action.payload })
    case types.SET_REFUND_ADDRESS:
      return Object.assign({}, state, { refund_address: action.payload })
    default:
      return state
  }
}
