import i18next from 'i18next'
import Backend from 'i18next-xhr-backend'
import LngDetector from 'i18next-browser-languagedetector'
import Cache from 'i18next-localstorage-cache'
import { reactI18nextModule } from 'react-i18next'

import config from './config'

const i18nextOptions = {
  fallbackLng: 'en',
  load: 'all',
  ns: ['translation'],
  defaultNS: 'translation',

  debug: config.isDev,
  // saveMissing: config.isDev,
  // updateMissing: false, // Caution: use to overwrite backend translations with defaultValue
  // saveMissingTo: 'all',

  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: false,
    nsMode: 'default',
    hashTransKey: (defaultValue) => {
      throw new Error(`Missing i18nKey prop on Trans component surrounding "${defaultValue}"`)
    },
    defaultTransParent: 'span'
  },

  interpolation: {
    escapeValue: false // not needed for react!!
  },

  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.{{lng}}.json'
  },

  detection: {
    lookupQuerystring: 'lng'
  },

  cache: {
    enabled: !config.isDev,

    // prefix for stored languages
    prefix: 'i18next_res_',

    // Contrary to cookies behavior, the cache will respect updates to expirationTime.
    // If you set 7 days and later update to 10 days, the cache will persist for 10 days
    expirationTime: 1000 * 60 * 60 * 24 * 1, // 1 day

    // Passing in a versions object (ex.: versions: { en: 'v1.2', fr: 'v1.1' }) will give
    // you control over the cache based on translations version. This setting works along
    // expirationTime, so a cached translation will still expire even though the version
    // did not change. You can still set expirationTime far into the future to avoid this.
    versions: {}
  },

  parseMissingKeyHandler: (key) => {
    if (config.isDev) {
      console.error(`Key missing: ${key}`)
    }
    return key
  }
}

i18next
  .use(Cache)
  .use(Backend)
  .use(LngDetector)
  .use(reactI18nextModule)
  .init(i18nextOptions)

export default i18next
