import { DateTime } from 'luxon'
import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
      return initialState
    case types.RESET_PRICE:
      return Object.assign({}, state, {
        [action.payload.category]: {}
      })
    case types.FETCH_PRICE:
      return Object.assign({}, state, {
        [action.payload.category]: Object.assign({}, state[action.payload.category], {
          isFetching: true
        })
      })
    case types.FETCH_PRICE_CLEAR:
      return Object.assign({}, state, {
        [action.payload.category]: Object.assign({}, state[action.payload.category], {
          isFetching: false
        })
      })
    case types.SET_PRICE:
      return Object.assign({}, state, {
        [action.payload.category]: Object.assign({}, action.payload.price, {
          isFetching: false,
          expiresAt: DateTime.local().plus({ minutes: 5 }).toISO()
        })
      })
    default:
      return state
  }
}
