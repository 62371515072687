export const RESET_ALL = 'RESET_ALL'
export const RESET_ASSET = 'RESET_ASSET'
export const RESET_LOCATION = 'RESET_LOCATION'
export const RESET_GEO = 'RESET_GEO'
export const RESET_ACCOUNT = 'RESET_ACCOUNT'
export const RESET_SURVEY = 'RESET_SURVEY'
export const RESET_IDENTIFICATION = 'RESET_IDENTIFICATION'
export const RESET_LEVEL_UP_IFRAME = 'RESET_LEVEL_UP_IFRAME'
export const RESET_MACHINE = 'RESET_MACHINE'
export const RESET_SESSION = 'RESET_SESSION'
export const RESET_TX = 'RESET_TX'
export const RESET_PRICE = 'RESET_PRICE'
export const RESET_PROMO = 'RESET_PROMO'
export const RESET_REFUND = 'RESET_REFUND'
export const RESET_LEVEL_UP_PROGRESS_STEPS = 'RESET_LEVEL_UP_PROGRESS_STEPS'
export const RESET_LIMITS = 'RESET_LIMITS'

export const SET_ASSET = 'SET_ASSET'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_GEO = 'SET_GEO'
export const SET_PRICE = 'SET_PRICE'
export const SET_ORDER = 'SET_ORDER'
export const SET_TX = 'SET_TX'
export const SET_REF_TX = 'SET_REF_TX'
export const SET_MQ = 'SET_MQ'
export const SET_LEVELS = 'SET_LEVELS'
export const SET_LIMITS = 'SET_LIMITS'
export const SET_TIMER = 'SET_TIMER'
export const SET_SESSION_STATUS = 'SET_SESSION_STATUS'
export const SET_REFUND_ADDRESS = 'SET_REFUND_ADDRESS'
export const SET_MACHINE = 'SET_MACHINE'
export const SET_MACHINE_RESOURCES = 'SET_MACHINE_RESOURCES'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const SET_AUTH_CONFIRMED_AT = 'SET_AUTH_CONFIRMED_AT'
export const SET_SURVEY = 'SET_SURVEY'
export const SET_FAAST_CURRENCIES = 'SET_FAAST_CURRENCIES'
export const SET_TERMS_REQUIRED = 'SET_TERMS_REQUIRED'
export const SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED'
/* export const SET_SELL_SELECTED = 'SET_SELL_SELECTED' */
export const SET_SELL_INITIATED = 'SET_SELL_INITIATED'
export const SET_LEVEL_UP_IFRAME = 'SET_LEVEL_UP_IFRAME'
export const SET_SELL_LOCATION = 'SET_SELL_LOCATION'
export const SET_SELL_ASSET = 'SET_SELL_ASSET'
export const SET_SELL_TERMS = 'SET_SELL_TERMS'
export const SET_SELL_PROMO_CODE_ACCEPTED = 'SET_SELL_PROMO_CODE_ACCEPTED'
export const SET_SELL_PROMO = 'SET_SELL_PROMO'
export const SET_SELL_MACHINE = 'SET_SELL_MACHINE'
export const SET_SELL_AMOUNTS = 'SET_SELL_AMOUNTS'
export const SET_SELL_REFUND_ADDRESS = 'SET_SELL_REFUND_ADDRESS'
export const SET_SELL_INITIAL_REQUEST = 'SET_SELL_INITIAL_REQUEST'
export const SET_SELL_CONFIRMED = 'SET_SELL_CONFIRMED'
export const SET_SELL_MINIMUM_WITHDRAWAL = 'SET_SELL_MINIMUM_WITHDRAWAL'
export const SET_REFUND_TRANSACTION = 'SET_REFUND_TRANSACTION'
export const SET_REFUND_SWAP = 'SET_REFUND_SWAP'
export const SET_REFUND_CONFIRMED = 'SET_REFUND_CONFIRMED'
export const SET_REFUND_SUBMITTED = 'SET_REFUND_SUBMITTED'
export const SET_FLOW = 'SET_FLOW'
export const SET_CURRENCIES = 'SET_CURRENCIES'
export const SET_PROMO = 'SET_PROMO'
export const SET_PROVIDER = 'SET_PROVIDER'
export const SET_LEVEL_UP_PROGRESS_STEPS = 'SET_LEVEL_UP_PROGRESS_STEPS'

export const ADVANCE_LEVEL_UP_PROGRESS_STEP = 'ADVANCE_LEVEL_UP_PROGRESS_STEP'

export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS'

export const FETCH_GEO = 'FETCH_GEO'
export const FETCH_PRICE = 'FETCH_PRICE'
export const FETCH_PRICE_CLEAR = 'FETCH_PRICE_CLEAR'
export const FETCH_LEVELS = 'FETCH_LEVELS'
export const FETCH_LIMITS = 'FETCH_LIMITS'
export const FETCH_FAAST = 'FETCH_FAAST'
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES'

export const I18N_READY = 'I18N_READY'
export const DISALLOW_GEO = 'DISALLOW_GEO'

export const START_AUTH = 'START_AUTH'
export const END_AUTH = 'END_AUTH'
export const REAUTH_REQUIRED = 'REAUTH_REQUIRED'

export const ADD_IDENTIFICATION = 'ADD_IDENTIFICATION'
export const REMOVE_IDENTIFICATION = 'REMOVE_IDENTIFICATION'
export const ADD_ID_URL = 'ADD_ID_URL'
export const UPLOAD_ID_ERROR = 'UPLOAD_ID_ERROR'
export const UPLOAD_ID_SUCCESS = 'UPLOAD_ID_SUCCESS'
export const UPLOAD_ID_ISFETCHING = 'UPLOAD_ID_ISFETCHING'

export const PRICE_FETCH_REQUESTS = 'PRICE_FETCH_REQUESTS'
