class Analytics {
  constructor (props) {
    this.debug = props.debug
    this.setServices(props)

    if (this.debug) console.log('ANALYTICS initialized. Services:', this.services)
  }

  setServices (props) {
    if (!this.services) this.services = {}
    if (props.amplitude) {
      const a = props.amplitude
      if (a.key) {
        window.amplitude.getInstance(a.name).init(a.key)
        if (!this.services.amplitude) this.services.amplitude = []
        this.services.amplitude.push(a)
      }
    }
    if (props.segment) {
      if (!window.analytics) throw new Error('Segment not set up')
      const servicesKeys = Object.keys(this.services)
      if (servicesKeys.length > 1 || (servicesKeys === 1 && servicesKeys[0] !== 'segment')) throw new Error('Segment should be only service')

      this.services.segment = true
      window.analytics.load(props.segment)
    }
  }

  identify (id, data, destinations) {
    if (this.services.amplitude) {
      this.services.amplitude.forEach(d => {
        if (!destinations || destinations.includes(d.name)) {
          const a = window.amplitude.getInstance(d.name)
          a.setUserId(id)
          if (typeof data === 'object') a.setUserProperties(data)
        }
      })
    }
    if (this.services.segment) {
      window.analytics.identify(id, data)
    }
    if (this.debug) {
      console.log('ANALYTICS - Identify:', id, data)
    }
  }

  reset (data, destinations) {
    if (this.services.amplitude) {
      this.services.amplitude.forEach(d => {
        if (!destinations || destinations.includes(d.name)) {
          const a = window.amplitude.getInstance(d.name)
          a.setUserId(null)
          a.regenerateDeviceId()
        }
      })
    }
    if (this.services.segment) {
      window.analytics.reset()
    }
    if (this.debug) {
      console.log('ANALYTICS - Reset')
    }
  }

  track (event, properties = {}, options = {}, destinations) {
    if (this.services.amplitude) {
      this.services.amplitude.forEach(d => {
        if (!destinations || destinations.includes(d.name)) {
          window.amplitude.getInstance(d.name).logEvent(event, properties || {})
        }
      })
    }
    if (this.services.segment) {
      window.analytics.track(event, properties, options)
    }
    if (this.debug) {
      console.log('ANALYTICS - Track:', event, properties, options)
    }
  }

  page (title, properties = {}, destinations) {
    if (this.services.amplitude) {
      this.services.amplitude.forEach(d => {
        if (!destinations || destinations.includes(d.name)) {
          const a = window.amplitude.getInstance(d.name)
          a.logEvent(`Viewed ${title} Page`, properties)
        }
      })
    }
    if (this.services.segment) {
      window.analytics.page(title, properties)
    }
    if (this.debug) {
      console.log('ANALYTICS - Page:', title, properties)
    }
  }
}

export default Analytics
