import React from 'react'
import { translate } from 'react-i18next'
import { Form } from 'reactstrap'
import { reduxForm, Field } from 'redux-form'
import ReactCodeInput from 'react-code-input'

import FormsInput from 'components/Forms/Input'
import FormsSubmit from 'components/Forms/Submit'
import { validateVerificationCodeForm } from 'lib/validations'

import './styles.css'

const CodeInput = (props) => (
  <ReactCodeInput type='number' fields={6} {...props.input} />
)

const VerificationForm = reduxForm({
  form: 'verificationCode',
  validate: validateVerificationCodeForm
})(translate()((props) => {
  const { handleSubmit, submitting, t, showInput } = props
  return (
    <Form className='@px-2.5 sm:@px-5' onSubmit={handleSubmit} autoComplete='false' >
      <input type='hidden' value='no-autofill' />
      <label className='@m-auto @text-left @text-lg @text-gray-700 @font-normal @leading-7 @tracking-tight @pb-5'>
        {t('verification.instructions', {
          defaultValue: 'Enter the verification code sent to your mobile number'
        })}
        :
      </label>
      <p className='@border-2 @border-dashed @border-gray-300 @bg-gradient-to-br @from-gray-100 @to-gray-200 @text-2xl @font-light @text-gray-700 @text-opacity-75 @tracking-widest @leading-7 @uppercase @text-center @py-3 @mb-9'>
        ({props.mobileNumber})
      </p>
      {/* Safari iOS does weird css stuff with ReactCodeInput */}
      {(props.isSafariIos &&
        <div id='code-input'>
          <Field
            name='code'
            type='number'
            label={props.t('general.code', {
              defaultValue: 'Code'
            })}
            component={FormsInput}
            noAutoComplete
          />
        </div>) ||
        <div id='code-input' style={{ display: 'flex', justifyContent: 'center' }}>
          {showInput &&
            <Field
              name='code'
              component={CodeInput}
            />
          }
        </div>
      }
      <div style={{ margin: '0 auto' }}>
        <FormsSubmit id='verificationCodeSubmit' text={t('general.nextStep', { defaultValue: 'Next Step' })} submitting={submitting} spinner />
      </div>
    </Form>
  )
}))

export default VerificationForm
