import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState, {
  allow: false,
  show: false,
  link: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_SESSION:
    case types.RESET_SURVEY:
      return initialState
    case types.SET_SURVEY:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}
