import config from 'config'
import * as types from 'actions/actionTypes'

const initialState = Object.assign({
  initiated: null, // keep this null for requirements check
  submitted: false,
  swap: false,
  txn: {}
}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_REFUND:
      return initialState
    case types.SET_REFUND_TRANSACTION:
      return Object.assign({}, state, { initiated: true, txn: action.payload })
    case types.SET_REFUND_SWAP:
      return Object.assign({}, state, { swap: action.payload })
    case types.SET_REFUND_SUBMITTED:
      return Object.assign({}, state, { submitted: true })
    default:
      return state
  }
}
