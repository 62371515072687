import config from 'config'

const page = (name, properties, destinations) => {
  if (window.btm.analytics) {
    window.btm.analytics.page(name, properties, destinations)
  }
}

const track = (event, props, options, destinations) => {
  const properties = Object.assign({}, props, { appVersion: config.appVersion })
  if (window.btm.analytics) {
    window.btm.analytics.track(event, properties, options, destinations)
  }
}

const trackError = (request, error, props, options, destinations) => {
  track('ERROR', Object.assign({}, props, { request, error, appVersion: config.appVersion }), options, destinations)
}

const identify = (user, destinations) => {
  if (window.btm.analytics && user && user.id != null) {
    window.btm.analytics.identify(user.id, user, destinations)
  }
}

const reset = (destinations) => {
  if (window.btm.analytics) {
    window.btm.analytics.reset(destinations)
  }
}

export default {
  page,
  track,
  trackError,
  identify,
  reset
}
