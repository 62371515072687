import React, { Component, Fragment } from 'react'

import config from 'config'

export default () => (Wrapped) => {
  class Logger extends Component {
    constructor (props) {
      super(props)

      this.state = {
        ready: false,
        logs: []
      }
    }

    componentDidMount () {
      window.btm.logger = (...args) => {
        if (config.enableLogger) {
          console.log(...args)
          let msg = `[${new Date().toISOString()}]`
          args.forEach(l => {
            if (typeof l === 'object' && l !== null) {
              try {
                l = JSON.stringify(l)
              } catch (e) {
                //
              }
            }
            msg += ` ${l}`
          })

          this.state.logs.push(msg)
          this.setState({ logs: [].concat(this.state.logs) })
        }
      }
      this.setState({ ready: true })
    }

    render () {
      if (!this.state.ready) return null
      if (!config.enableLogger) return <Wrapped {...this.props} />
      return (
        <Fragment>
          <Wrapped {...this.props} />
          <pre style={{ margin: '100px 30px' }}>
            LOGGER
            <hr />
            {this.state.logs.map((log, i) => (
              <Fragment key={i}>
                {log}<br />
                <hr />
              </Fragment>
            ))}
          </pre>
        </Fragment>
      )
    }
  }

  return Logger
}
