import config from 'config'
import * as types from 'actions/actionTypes'

const initialState = Object.assign({}, config.initialState, {
  isFetching: false
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CURRENCIES:
      return Object.assign({}, state, { isFetching: true })
    case types.SET_CURRENCIES:
      return Object.assign({}, state, {
        isFetching: false,
        list: action.payload
      })
    default:
      return state
  }
}
