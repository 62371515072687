import moment from 'moment'

import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState, {
  persistExpiresAt: moment().add(1, 'M').toISOString(), // 1 month
  isFetching: false,
  disallowed: false
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_GEO:
      return initialState
    case types.SET_GEO:
      return Object.assign({}, state, action.payload, { isFetching: false })
    case types.FETCH_GEO:
      return Object.assign({}, state, { isFetching: true })
    case types.DISALLOW_GEO:
      return Object.assign({}, state, { isFetching: false, disallowed: true })
    default:
      return state
  }
}
