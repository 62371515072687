import { get, patch, post, putNew } from './fetch'
import analytics from 'lib/analytics'
import { setAccount, resetAccount, fetchLimits, setLimits } from 'actions'
import config from 'config'

export const getAccount = () => async dispatch => {
  const path = '/client'
  let data
  try {
    data = await dispatch(get(path))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    dispatch(resetAccount())
    if (e.message === '204') return
    analytics.trackError('client', e.message)
    throw e
  }
  dispatch(setAccount(data.result))
  return data.result
}

export const getAccountDetails = () => async dispatch => {
  const path = '/client/details'
  let data
  try {
    data = await dispatch(get(path))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('GET client/details', e.message)
    throw e
  }
  return data.result
}

export const updateAccountDetails = (updates) => async dispatch => {
  const path = '/client/details'
  let data
  try {
    data = await dispatch(patch(path, updates))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('PATCH client/details', e.message)
    throw e
  }
  return data.result
}

export const sendNonce = (operatorId, identity, recaptcha, options = {}) => async dispatch => {
  let path = '/client/verification-code'
  if (config.wl && config.wl.organization_id) operatorId = config.wl.organization_id
  const request = options.changePrimaryContact ? putNew : post
  const body = {
    operator_id: operatorId,
    phone_number: identity,
    'g-recaptcha-response': recaptcha
  }
  if (!operatorId) {
    if (options.oldTel) throw new Error('sendNonce_support')
    body.operator_id = '-'
    path += '/operator-id-lookup'
  } else if (options.oldTel) {
    path += '/phone-number-update'
    body.old_phone_number = options.oldTel
  }
  let data
  try {
    data = await dispatch(request(path, body))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('client/verification-code', e.message)
    console.error(e.message)
    let errorCode
    switch (e.message) {
      case 'Invalid number':
        errorCode = 'sendNonce_invalid'
        break
      case 'Blocked number':
        errorCode = 'sendNonce_support'
        break
      case '403':
        errorCode = 'sendNonce_403'
        break
      case 'Not primary contact':
        errorCode = 'sendNonce_not_primary'
        break
      case 'Customer not found':
        errorCode = 'sendNonce_not_customer'
        break
      default:
        errorCode = 'sendNonce_generic'
    }
    throw new Error(errorCode)
  }
  return data.result
}

export const checkNonce = (operatorId, phone, key, code, options = {}) => async dispatch => {
  let path = options.changePrimaryContact ? '/client/primary-contact' : '/client/token'
  if (config.wl && config.wl.organization_id) operatorId = config.wl.organization_id
  const request = options.changePrimaryContact ? putNew : post
  const body = {
    operator_id: operatorId || '-',
    phone_number: phone,
    key,
    verification_code: code
  }
  let data
  try {
    data = await dispatch(request(path, body))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    console.error(e.message)
    analytics.trackError(path, e.message)
    let errorCode
    switch (e.message) {
      case 'Invalid code':
        errorCode = 'checkNonce_invalid'
        break
      case 'Max attempts reached':
      case 'Expired code':
        errorCode = 'checkNonce_reset'
        break
      case 'Unavailable phone_number':
        errorCode = 'checkNonce_claimed'
        break
      default:
        errorCode = 'checkNonce_generic'
    }
    throw new Error(errorCode)
  }
  const result = data.result
  if (!options.changePrimaryContact) {
    dispatch(setAccount(result))
  }
  return result
}

export const ott = (token) => async dispatch => {
  const path = '/client/ott'
  const body = { ott: token }
  let data
  try {
    data = await dispatch(post(path, body))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    console.error(e.message)
    analytics.trackError(path, e.message)
    throw e
  }
  const result = data.result
  const account = {
    phone_number: result.phone_number,
    client_id: result.client_id,
    setFor: result.type
  }
  dispatch(setAccount(account))
  return result
}

export const getLimits = (machineId) => async dispatch => {
  let path = `/client/limits`
  if (machineId) path += `?machine_id=${machineId}`
  let data
  dispatch(fetchLimits())
  try {
    data = await dispatch(get(path))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('client/limits', e.message)
    dispatch(setLimits({}))
    throw e
  }
  const result = Object.assign({}, data.result, { machine_id: machineId })
  dispatch(setLimits(result))
  return result
}

export const idVerify = (payload) => async dispatch => {
  let path = '/client/id-verify'
  let data
  try {
    data = await dispatch(putNew(path, payload))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('client/id-verify', e.message)
    throw e
  }
  return data.result
}

export const idVerifyComplete = (payload) => async dispatch => {
  let path = '/client/id-verify/complete'
  let data
  try {
    data = await dispatch(putNew(path, payload))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('client/id-verify/complete', e.message)
    throw e
  }
  return data.result
}

export const addressVerify = (payload) => async dispatch => {
  let path = '/client/address-verify'
  let data
  try {
    data = await dispatch(putNew(path, payload))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('client/address-verify', e.message)
    throw e
  }
  return data.result
}

export const operators = () => async dispatch => {
  let path = '/client/operators'
  let data
  try {
    data = await dispatch(get(path))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('GET client/operators', e.message)
    throw e
  }
  return data.result
}

export const updateToken = (operatorId) => async dispatch => {
  let path = '/client/token'
  let data
  try {
    data = await dispatch(patch(path, { operator_id: operatorId }))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('PATCH client/token', e.message)
    throw e
  }
  const result = data.result
  dispatch(setAccount(result))
  return result
}

export const eddRequest = (organization) => async dispatch => {
  let path = `/client/edd-request`
  let data
  try {
    data = await dispatch(putNew(path, {}))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.message || data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('client/edd-request/', e.message)
    throw e
  }
  return data.result
}