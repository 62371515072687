import BigNumber from 'bignumber.js'
BigNumber.config({ EXPONENTIAL_AT: [-9, 20] })
window.BigNumber = BigNumber

export const TEN = new BigNumber(10)

export const toBigNumber = (value = 0) => {
  if (value === '0x') value = 0
  if (!(value instanceof BigNumber)) {
    try {
      const bn = new BigNumber(String(value))
      return bn
    } catch (e) {
      return new BigNumber(0)
    }
  }
  return value
}

export const toMainDenomination = (value, decimals) => {
  value = toBigNumber(value)
  const power = TEN.exponentiatedBy(decimals)
  return value.div(power)
}

export const toSmallestDenomination = (value, decimals) => {
  value = toBigNumber(value)
  const power = TEN.exponentiatedBy(decimals)
  return value.times(power)
}

export const toPrecision = (amount, decimals) => {
  amount = toBigNumber(amount)
  return amount.decimalPlaces(decimals)
}

export const toUnit = (amount, rate, decimals, rateFrom) => {
  amount = toBigNumber(amount)
  rate = toBigNumber(rate)
  let conversion = rateFrom ? amount.div(rate) : amount.times(rate)
  if (!conversion.isFinite()) conversion = toBigNumber(0)
  return toPrecision(conversion, decimals)
}

export const toPercentage = (amount, total) => {
  amount = toBigNumber(amount)
  total = toBigNumber(total)
  return amount.div(total).times(100).decimalPlaces(2)
}

export const toTxFee = (gasLimit, gasPrice) => {
  gasLimit = toBigNumber(gasLimit)
  gasPrice = toBigNumber(gasPrice)
  const power = TEN.exponentiatedBy(18)
  return gasLimit.times(gasPrice).div(power)
}
