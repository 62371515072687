import config from 'config'
import * as types from 'actions/actionTypes'

const initialState = Object.assign({
  isEdge: false
}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROVIDER:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}
