import config from 'config'
import * as types from 'actions/actionTypes'

const initialState = Object.assign({
  asset: null,
  location: null,
  promo: null,
  initiated: null, // keep this null for requirements check
  from: 0,
  fromCurrency: '',
  to: 0,
  toCurrency: '',
  refundAddress: '',
  providedInitialRequest: false,
  confirmed: false,
  terms: [],
  promoCodeAccepted: false,
  minimumWithdrawal: 0
}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_SESSION:
      return initialState
    case types.SET_SELL_INITIATED:
      return Object.assign({}, state, { initiated: true })
    case types.SET_SELL_ASSET:
      return Object.assign({}, state, { asset: action.payload })
    case types.SET_SELL_LOCATION:
      return Object.assign({}, state, { location: action.payload })
    case types.SET_SELL_TERMS:
      return Object.assign({}, state, { terms: action.payload })
    case types.SET_SELL_PROMO_CODE_ACCEPTED:
      return Object.assign({}, state, { promoCodeAccepted: action.payload })
    case types.SET_SELL_PROMO:
      return Object.assign({}, state, { promo: action.payload })
    case types.SET_SELL_AMOUNTS:
      return Object.assign({}, state, action.payload)
    case types.SET_SELL_REFUND_ADDRESS:
      return Object.assign({}, state, { refundAddress: action.payload })
    case types.SET_SELL_INITIAL_REQUEST:
      return Object.assign({}, state, { providedInitialRequest: action.payload })
    case types.SET_SELL_CONFIRMED:
      return Object.assign({}, state, { confirmed: action.payload })
    case types.SET_SELL_MINIMUM_WITHDRAWAL:
      return Object.assign({}, state, { minimumWithdrawal: action.payload })
    default:
      return state
  }
}
