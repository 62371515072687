import { fetchCurrencies, setCurrencies } from 'actions'
import analytics from 'lib/analytics'
import { get } from './fetch'

export const getCurrencies = () => async dispatch => {
  dispatch(fetchCurrencies())
  const path = '/currency'
  let data
  try {
    data = await dispatch(get(path))
    if (typeof data !== 'object') throw new Error('Unexpected response')
    if (data.error) throw new Error(data.error)
    if (!data.result) throw new Error('Missing result')
  } catch (e) {
    analytics.trackError('getCurrencies', e.message)
    dispatch(setCurrencies([]))
    throw e
  }
  dispatch(setCurrencies(data.result))
  return data.result
}
