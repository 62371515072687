import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_SESSION:
    case types.SET_MACHINE:
    case types.RESET_LIMITS:
      return initialState
    case types.FETCH_LIMITS:
      return Object.assign({}, state, { isFetching: true })
    case types.SET_LIMITS:
      return Object.assign({}, state, action.payload, { isFetching: false })
    default:
      return state
  }
}
