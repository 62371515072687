import React, { Component } from 'react'

export default () => (Wrapped) => {
  class ScrollToTop extends Component {
    componentDidUpdate (prevProps) {
      const location = this.props.location || {}
      const prevLocation = prevProps.location || {}
      if (location.pathname !== prevLocation.pathname) {
        window.scrollTo(0, 0)
      }
    }

    render () {
      return <Wrapped {...this.props} />
    }
  }

  return ScrollToTop
}
