import * as types from 'actions/actionTypes'

const initialState = {
  steps: [],
  isActiveLevelUpFlow: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_LEVEL_UP_PROGRESS_STEPS:
      return initialState
    case types.SET_LEVEL_UP_PROGRESS_STEPS:
      return {
        ...state,
        steps: action.payload.steps,
        isActiveLevelUpFlow: true
      }
    case types.ADVANCE_LEVEL_UP_PROGRESS_STEP:
      return {
        ...state,
        steps: [...state.steps.slice(1)]
      }
    default:
      return state
  }
}
