/* global fetch */
import queryString from 'querystring'
import { toastr } from 'react-redux-toastr'
import config from 'config'
import i18n from 'i18n'
import analytics from 'lib/analytics'
import { getLanguage } from 'lib/misc'

const pathPrefix = config.pathPrefix || ''

const newVersion = () => {
  return new Promise((resolve, reject) => {
    analytics.track('New Version Notification')
    toastr.confirm(i18n.t('general.newVersion', {
      defaultValue: 'A new version of the site has been released. The page will now be refreshed to load the latest version'
    }), {
      disableCancel: true,
      onOk: () => window.location.reload()
    })
    window.setTimeout(() => {
      window.location.reload()
    }, 30000)
  })
}

const parseResponse = (response, statusOnly) => (dispatch) => {
  switch (response.status) {
    case 200:
    case 201:
    case 304:
    case 400:
    case 500:
      if (statusOnly) return Promise.resolve(response.status)
      return response.json()
    case 401:
      window.location.reload()
      break
    case 474:
      return newVersion()
    default:
      throw new Error(response.status)
  }
}

export const get = (path, options = {}) => (dispatch) => {
  const host = options.host || pathPrefix
  const url = host + path

  const init = {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  }
  if (!options.host) {
    init.credentials = 'same-origin'
    init.headers['Accept-Language'] = getLanguage()
    init.headers['X-CSRF-Token'] = config.csrfToken
    init.headers['app-version'] = config.appVersion
  }
  if (options.signal) init.signal = options.signal

  return fetch(url, init)
    .then(res => dispatch(parseResponse(res)))
}

export const request = (method, path, body, options = {}) => (dispatch) => {
  const newBody = {}
  const query = {}
  const host = options.host || pathPrefix
  for (const key in body) {
    if (typeof body[key] === 'string') {
      newBody[key] = body[key].trim()
    } else {
      newBody[key] = body[key]
    }
  }
  if (newBody['g-recaptcha-response']) {
    query['g-recaptcha-response'] = newBody['g-recaptcha-response']
    delete newBody['g-recaptcha-response']
  }
  let url = `${host}${path}`
  if (Object.keys(query).length) {
    url += `?${queryString.stringify(query)}`
  }

  const init = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newBody)
  }
  if (!options.host) {
    init.credentials = 'same-origin'
    init.headers['Accept-Language'] = getLanguage()
    init.headers['X-CSRF-Token'] = config.csrfToken
    init.headers['app-version'] = config.appVersion
  }
  if (options.signal) init.signal = options.signal
  return (
    fetch(url, init)
      .then(res => dispatch(parseResponse(res)))
  )
}

export const post = (path, body, options = {}) => (dispatch) => {
  return dispatch(request('POST', path, body, options))
}

export const patch = (path, body, options = {}) => (dispatch) => {
  return dispatch(request('PATCH', path, body, options))
}

export const putNew = (path, body, options = {}) => (dispatch) => {
  return dispatch(request('PUT', path, body, options))
}

export const postFormData = (path, bodyPost) => (dispatch) => {
  const newBody = {}
  for (const key in bodyPost) {
    if (typeof bodyPost[key] === 'string') {
      newBody[key] = bodyPost[key].trim()
    } else {
      newBody[key] = bodyPost[key]
    }
  }

  return (
    fetch(path, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'app-version': config.appVersion
      },
      body: queryString.stringify(bodyPost)
    })
      .then(res => dispatch(parseResponse(res)))
  )
}

export const put = (path, file) => (dispatch) => {
  return (
    fetch(path, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': config.csrfToken,
        'app-version': config.appVersion
      },
      body: file
    })
      .then(res => dispatch(parseResponse(res, true)))
  )
}
