import config from '../config'
import * as types from '../actions/actionTypes'

const initialState = Object.assign({}, config.initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_SESSION:
      return initialState
    case types.SET_TIMER:
      return Object.assign({}, state, {
        timeout: action.payload
      })
    default:
      return state
  }
}
