import * as types from '../actions/actionTypes'

const initialState = {
  setFor: '',
  authRunning: false,
  onContinue: '',
  alwaysConfirm: false,
  confirmed: false,
  prepopulate: '',
  reauthRequired: false,
  oldTel: '',
  restriction: '',
  selectedOperator: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_ALL:
    case types.RESET_ACCOUNT:
      return initialState
    case types.SET_ACCOUNT:
      return Object.assign({}, state, action.payload)
    case types.SET_AUTH_CONFIRMED_AT:
      return Object.assign({}, state, { confirmed: true, confirmedAt: action.payload })
    case types.START_AUTH:
      return Object.assign({}, state.client_id ? state : initialState, {
        authRunning: true,
        onContinue: action.payload.onContinue,
        alwaysConfirm: !!action.payload.alwaysConfirm,
        confirmed: false,
        prepopulate: action.payload.prepopulate || '',
        oldTel: action.payload.oldTel || '',
        selectedOperator: action.payload.selectedOperator || ''
      })
    case types.END_AUTH:
      return Object.assign({}, state, {
        authRunning: false,
        setFor: '',
        prepopulate: ''
      })
    case types.REAUTH_REQUIRED:
      return Object.assign({}, state, { reauthRequired: true })
    default:
      return state
  }
}
