import moment from 'moment'
const config = Object.assign({}, window.env)

config.initialState = {
  persistExpiresAt: moment().add(60, 'm').toISOString() // 60 minutes
}

config.sessionStatus = {
  UNINITIATED: 'UNINITIATED',
  INITIATED_ORDER: 'INITIATED_ORDER',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  COINS_SENT: 'COINS_SENT',
  STATED_SENT: 'STATED_SENT',
  WALLET_SENT: 'WALLET_SENT',
  TIMED_OUT: 'TIMED_OUT',
  REFUND_SUBMITTED: 'REFUND_SUBMITTED'
}

config.flows = {
  SELL_BTC: 'SELL_BTC',
  SELL_ALTCOINS: 'SELL_ALTCOINS'
}

export default config
